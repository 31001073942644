import ia from "./ia.png";
import file from "./file.png";
import graph from "./graph.png";
import ticket from "./ticket.png";
import prompts from "./prompts.png";
import send from "./send.png";
import upload from "./upload.png";

const images = { ia, file, graph, ticket, prompts, send, upload };
export default images;
