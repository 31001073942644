import React, { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../scss/adminBusiness.scss";
import Link from '@mui/material/Link';
import AddAddress from "./GenericAddAddress";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBusinessAddressInfo, valSchBusinessAddress } from "../utils/formHelper";
import EditIcon from '@mui/icons-material/Edit';

const GenericAddress = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: initValBusinessAddressInfo(data, true),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessAddress()),
    onSubmit: async (formData) => {

    },
  });

  /* console.log('Datos de orden direccion', target) */

  return (

    <Paper className="paper">
      <Grid container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Grid>
          <Typography variant="h6" gutterBottom component="div" className="title-card">
            <strong>Dirección principal</strong>
          </Typography>
        </Grid>
        <Grid>
          <Button  onClick={() => { setOpen(true) }} variant="text" startIcon={<EditIcon />}>Editar</Button>
        </Grid>
      </Grid>
      <AddAddress data={data} open={open} setOpen={setOpen} UpdateInfo={UpdateInfo} target={target} modtitle="Editar dirección"/>

      <Grid container spacing="16px" mt="16px">
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="street"
            label="Dirección (Calle, torre, empresa)"

            size="small"
            value={formik.values.street}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} >
          <TextField
            fullWidth
            name="ext_number"
            label="No. Exterior"

            size="small"
            value={formik.values.ext_number}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} >
          <TextField
            fullWidth
            name="int_number"
            label="No. Interior"

            size="small"
            value={formik.values.int_number}
            InputProps={{
              readOnly: true,
            }}
          />

        </Grid>
        <Grid item xs={12} md={6} >

          <TextField
            fullWidth
            name="postal_code"
            label="Código Postal"
            
            size="small"
            value={formik.values.postal_code}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>


        <Grid item xs={12} md={6} >


          <TextField
            fullWidth
            name="state"
            label="Estado"

            size="small"
            value={formik.values.state}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} >


          <TextField
            fullWidth
            name="suburb"
            label="Colonia"

            size="small"
            value={formik.values.suburb}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} >




          <TextField
            fullWidth
            name="city"
            label="Ciudad/Municipio"

            size="small"
            value={formik.values.city}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="street_between"
            label="Calles con la que se cruza"
            size="small"
            value={formik.values.street_between}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="phone"
            label="Teléfono"
            size="small"
            value={formik.values.phone}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>


      </Grid>

    </Paper>

  );
}

export default GenericAddress;