import React, { useState, useEffect } from "react";
import BasicModal from "./GenericModal";
import Typography from "@mui/material/Typography";
import { TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBusinessAddressInfo, valSchBusinessAddress } from "../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import AddressManages, { ZipCode } from '../api/addressManages';
import { UseerrorOrsuccess, useNotification } from '../hooks/snackBarResponse'
import { useSnackbar } from 'notistack';
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';


const GenericAddAddress = ({ setOpen, open, UpdateInfo, data, target, modtitle }) => {
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [direction, setDirection] = useState("");
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified, setModified] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /* console.log('target', target) */


  const UpdateAddress = async (id, formData) => {

    let updateAddress = new AddressManages();
    const response = await updateAddress.editDataAuth(id, formData, logout);
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response))
    } else {
      sendNotification(UseerrorOrsuccess(response))
    }
    UpdateInfo();
  }
  const SetAddress = async (formData) => {
    let setAddress = new AddressManages();
    const response = await setAddress.postDataAuth(formData, logout);
    if (response?.data) {
      sendNotification(UseerrorOrsuccess(response))
    } else {
      sendNotification(UseerrorOrsuccess(response))
    }
    UpdateInfo();
  }

  const formik = useFormik({
    initialValues: initValBusinessAddressInfo(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessAddress()),
    onSubmit: async (formData, { resetForm }) => {
      if (target == 'supplier') {
        formik.values.supplier = data.id
      } else if (target == 'business') {
        formik.values.business = data.id
      } else if (target == 'branch') {
        formik.values.branch = data.id
      } else if (target == 'client') {
        formik.values.client = data.id
      }
      setLoading(true);
      /* console.log(formData)
      console.log(direction) */
      let longLati = direction.suburbs.find((e) => e.name == formik.values.suburb);
      /* console.log(longLati) */
      if (longLati) {
        formik.values.map_lat = longLati.latitude
        formik.values.map_lon = longLati.longitude
      }


      if (data.address) {
        UpdateAddress(data.address.id, formData)
        formik.resetForm();
      } else {
        SetAddress(formData);
      }
      setLoading(false);
      setOpen(false)
    },
  });
  useEffect(() => {
    /*    if(data.address.postal_code){
         GetDirections(data.address.postal_code)
       } */
    return () => {
      formik.resetForm();
    };
  }, [open]);

  useEffect(() => {
    if (data.address?.postal_code) {
      GetDirections(data.address.postal_code)
    }
  }, []);


  const GetDirections = (code) => {
    (async () => {
      if (code) {
        setLoadingCode(true)
        let irequestFilter = [];
        irequestFilter.push({ key: "code", value: `${code}` });
        let getDirec = new ZipCode();
        let response = await getDirec.filter(irequestFilter);

          if (response?.data.error) {
            console.log('error Generic')
            sendNotification({ custom: 'Codigo postal no valido', variant: 'error' })
          }

          if (response?.data) {
            setDirection(response.data);
            formik.values.state = response.data.state;
            formik.values.city = response.data.city;
            setFieldsLocked(true); // Bloquear los campos después de obtener los datos
          }
          setLoadingCode(false)
        } else {
          enqueueSnackbar("Se necesita el código postal", {
            variant: "error",
            //autoHideDuration: 1000,
            //action
          });
        }
    })();
  };

  const [fieldsLocked, setFieldsLocked] = useState(false);

  return (
    <BasicModal open={open} setOpen={setOpen} title={modtitle ? modtitle : "Agregar dirección" }>
      <form onSubmit={formik.handleSubmit} onChange={()=> setModified(true)}>
        <Grid container spacing="8px">
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="street"
              label="Dirección (Calle, torre, empresa)"
              margin="dense"
              size="small"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={
                formik.touched.street && Boolean(formik.errors.street)
              }

              helperText={
                formik.touched.street ? formik.errors.street : ""
              }
            />
          </Grid>

          <Grid item xs={12} md={6} >
            <TextField
              fullWidth
              name="ext_number"
              label="No. Exterior"

              size="small"
              value={formik.values.ext_number}
              onChange={formik.handleChange}
              error={
                formik.touched.ext_number && Boolean(formik.errors.ext_number)
              }

              helperText={
                formik.touched.ext_number ? formik.errors.ext_number : ""
              }
            />
          </Grid>



          <Grid item xs={12} md={6} >
            <TextField
              fullWidth
              name="int_number"
              label="No. Interior"

              size="small"
              value={formik.values.int_number}
              onChange={formik.handleChange}
              error={
                formik.touched.int_number && Boolean(formik.errors.int_number)
              }

              helperText={
                formik.touched.int_number ? formik.errors.int_number : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={6} >

            <TextField
              fullWidth
              name="postal_code"
              label="Código Postal"
              size="small"
              value={formik.values.postal_code}
              onChange={formik.handleChange}
              error={
                formik.touched.postal_code && Boolean(formik.errors.postal_code)
              }

              helperText={
                formik.touched.postal_code ? formik.errors.postal_code : ""
              }
            />
          </Grid>


          <Grid item xs={12} md={6} >

            <LoadingButton
              loading={loadingCode}
              size="large"
              fullWidth
              variant="contained"
              disabled={!formik.values.postal_code}
              onClick={() => { GetDirections(formik.values.postal_code) }}
            >
              Validar código postal
            </LoadingButton>
          </Grid>
          {/*  -------------------------------------------- */}





          <Grid item xs={12} md={6} >


            <FormControl fullWidth variant="outlined" size="small" >
              <InputLabel error={
                formik.touched.suburb &&
                Boolean(formik.errors.suburb)
              }>
                Colonia
              </InputLabel>
              <Select
                name="suburb"
                label="Colonia"
                value={formik.values.suburb}
                onChange={formik.handleChange}
                error={
                  formik.touched.suburb &&
                  Boolean(formik.errors.suburb)
                } >
                {direction?.suburbs && direction?.suburbs.map((data) => (
                  <MenuItem key={data.id} value={data.name} /* onClick={()=>{formik.values.map_lat = data.latitude; formik.values.map_lon = data.longitude}} */>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ display: formik.touched.suburb ? "" : "none", color: "error.main" }}>
                {formik.touched.suburb ? formik.errors.suburb : ""}
              </FormHelperText>
            </FormControl>

          </Grid>


          <Grid item xs={12} md={6} >
            <TextField
              fullWidth
              name="city"
              label="Ciudad/Municipio"
              disabled={fieldsLocked} // Disabilty the camp if fieldsLocked is true
              size="small"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={
                formik.touched.city && Boolean(formik.errors.city)
              }

              helperText={
                formik.touched.city ? formik.errors.city : ""
              }
            />
          </Grid>

          <Grid item xs={12} md={6} >
            <TextField
              fullWidth
              name="state"
              label="Estado"
              disabled={fieldsLocked} // Disabilty the camp if fieldsLocked is true
              size="small"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={
                formik.touched.state && Boolean(formik.errors.state)
              }

              helperText={
                formik.touched.state ? formik.errors.state : ""
              }
            />
          </Grid>



          <Grid item xs={12} md={6} >
            <TextField
              fullWidth
              name="phone"
              label="Teléfono"
              type={"number"}
              size="small"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={
                formik.touched.phone && Boolean(formik.errors.phone)
              }

              helperText={
                formik.touched.phone ? formik.errors.phone : ""
              }
            />




          </Grid>



          <Grid item xs={12}>
            <TextField
              fullWidth
              name="street_between"
              label="Calles con la que se cruza "

              size="small"
              value={formik.values.street_between}
              onChange={formik.handleChange}
              error={
                formik.touched.street_between && Boolean(formik.errors.street_between)
              }

              helperText={
                formik.touched.street_between ? formik.errors.street_between : ""
              }
            />

          </Grid>


        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            xs={12}
            sm={7}
            md={6}
            lg={6}
            xl={6}
            sx={{ marginTop: "15px" }}
          >
            <LoadingButton
              loading={loading}
              disabled={!modified}
              fullWidth
              type="submit"
              variant="contained"
              sx={{ color: "#fff" }}
            >
              {data.address ? "Editar" : "Agregar"}
            </LoadingButton>
          </Grid>
        </Grid>

      </form>


    </BasicModal>

  );
}

export default GenericAddAddress;