import { Container, Grid, TextareaAutosize, Typography } from "@mui/material";

const QAItems = [
  {
    title: "1. ¿Cómo se llama tu empresa?",
    information: "Incluye el nombre que quieres que vean tus clientes",
  },
  {
    title: "2. ¿Tu empresa ofrece productos, servicios o ambos?",
    information: "Elije lo que mejor describa tu negocio",
  },
  {
    title: "3. ¿Qué tipo de productos o servicios ofreces?",
    information: "Ejemplo: ropa casual, servicios de plomería, etc.",
  },
  {
    title: "4. Describe tus principales productos / servicios",
    information:
      "Incluye el nombre y una breve descripción.\n Por ejemplo, pantalones de mezclilla en varios cortes y colores, o reparaciones de tuberías de manera rápida y económica. ",
  },
  {
    title:
      "5. ¿Cuáles son los principales beneficios que tus productos o servicios brindan a tus clientes?",
    information:
      "Ejemplo: ahorro de tiempo, mejora de salud, conveniencia, etc.",
  },
  {
    title: "6. ¿A quién están dirigidos tus productos / servicios?",
    information:
      "Piensa en la edad, género, ubicación o estilo de vida de tus clientes principales.\n Ejemplo: mujeres y hombres de entre 20 y 35 años que viven en la Republica Méxicana que les gusta hacer ecoturismo.",
  },
  {
    title: "7. ¿Cuál es la historia de tu empresa?",
    information:
      "Describe cuándo y por qué la fundaste. Por ejemplo, puedes iniciar asi: comenzamos en 2018 porque notamos que había pocas opciones veganas de postres. ",
  },
  {
    title:
      "8. ¿Qué hace que tu empresa, producto o servicio sea diferente de la competencia?",
    information:
      "¿Ofreces algo único? ¿Mejor servicio, mayor calidad, precios más bajos, mejores tiempos de entrega?. Explícalo de manera sencilla. ",
  },
  {
    title: "9. ¿Cuál es el principal objetivo de tu tienda en línea?",
    information:
      "Por ejemplo, aumentar ventas, atraer nuevos clientes, mejorar la experiencia del cliente, tener un catálogo en línea, etc.",
  },
  {
    title: "10. ¿Tienes algún slogan o frase que acompañe a tu logotipo?",
    information: "Por ejemplo, “La mejor calidad al mejor precio”",
  },
  {
    title: "11. ¿Cómo describirías el estilo o personalidad de tu marca?",
    information:
      "Ejemplos: elegante, casual, profesional, relajada, moderna, conservadora, etc. Máximo menciona dos palabras.",
  },
  {
    title:
      "12. ¿Qué te gustaría que sientan tus clientes al leer tus mensajes?",
    information: "Ejemplos: confianza, entusiasmo, cercanía, inspiración, etc.",
  },
];
const Brief = () => {
  return (
    <Container sx={{ paddingY: "36px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom color="#60269E">
            <strong>Información para brief</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p" gutterBottom color="#605D5D">
            Este cuestionario te ayudará a construir la página de tu tienda en
            línea, haciéndola atractiva y efectiva para tu público. No necesitas
            tener conocimientos de marketing o ventas, solo responde estas
            preguntas de la forma más clara posible. ¡Vamos a comenzar!
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={5} xs={12} sx={{ marginTop: "20px" }}>
        {QAItems.map((item) => (
          <QA {...item} />
        ))}
      </Grid>

      <button
        style={{
          width: "100%",
          borderRadius: "50px",
          marginTop: "50px",
          backgroundColor: "#60269E",
          height: "40px",
          color: "#fff",
          border: 0,
          fontSize: "14px",
        }}
      >
        Enviar
      </button>
    </Container>
  );
};

export const QA = ({ title, information }) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "5px",
          paddingX: "35px",
          paddingY: "10px",
          boxShadow: 3,
        }}
        rowSpacing={2}
        xs={12}
      >
        <Grid item xs={12}>
          <Typography variant="p" gutterBottom color="#0A0A0A">
            <strong>{title}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="p"
            gutterBottom
            color="#605D5D"
            sx={{ whiteSpace: "pre-line" }}
          >
            {information}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            maxRows={4}
            style={{
              width: "100%",
              height: "58px",
              resize: "none",
              borderRadius: "5px",
              padding: "9px",
              fontSize: "14px",
            }}
            placeholder="Escribe tu respuesta"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Brief;
