import React from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const GenericEmptyTable = (props) => {
  const { msg, justify } = props;

  return (
    <Grid container justifyContent={justify} alignItems="center">
      <Grid item xs={6}>
        <p
          style={{
            fontStyle: "italic",
            padding: "24px 0px",
            textAlign: "center",
          }}
        >
          {msg}
        </p>
      </Grid>
    </Grid>
  );
};

export default GenericEmptyTable;

GenericEmptyTable.propTypes = {
  msg: PropTypes.string,
  justify: PropTypes.string,
};

GenericEmptyTable.defaultProps = {
  msg: "No hay datos para mostrar en esta tabla",
  justify: "center",
};
