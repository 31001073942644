import React from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

//generic list component to render a list of items with an icon and a title and a function to handle the click event on the item
export default function GenericList({ item, handleChangeTab, handleChangeId }) {
  return (
    <Box sx={{ width: "100%" }}>
      <List>
        {item.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={() => {
              handleChangeTab();
              handleChangeId(item.id);
            }}

          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            <ListItemIcon>
              <ArrowForwardIosIcon
                sx={{ display: "flex", justifyContent: "right", width: "100%" }}
              />
            </ListItemIcon>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}

export function DataPrompts({ data }) {
  return (
    <List>
      {data.map((item, index) => (
        <Box key={index}>
            <Typography variant="h6" gutterBottom component="div" sx={{fontSize:{xs:'1.1em', md:'1em', xl:'1em'}, paddingBottom:"20px" }} >
                {item.title}
            </Typography>
            <Box sx={{width:"100%",  }} >
            <TextField
            id="filled-disabled"
            label="Prompts"
            defaultValue={item.description}
            variant="filled"
            fullWidth
            sx={{borderRadius:"15px"}}
            multiline
            rows={12}
            />
            <Box sx={{display:"flex", justifyContent:"right", paddingTop:"20px" }} >
                <Button variant="contained">
                    Usar
                </Button>
            </Box>
            </Box>
                
        </Box>
      ))}
    </List>
  );
}


