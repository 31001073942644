/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, InputAdornment, Button, Input, Box, Stack, Divider, Card, CardActions, CardContent, CardMedia } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValDescription,
  valSchDescription,
  initValKeyWord,
  valSchKeyWord,
} from "../../utils/formHelper";
import { BusinessMetaData, BusinessSettingsColors, BusinessTypography } from "../../api/businessManages";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import { LoadingButton } from "@mui/lab";
import { BusinessKeyword, BusinessImages } from "../../api/businessManages";
import { useSnackbar } from "notistack";
/* import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined"; */
import ImgEdit from "./ImgEdit";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SocialMedia from "../../pages/admin/business/socialMedia";
import CountWords from "../CountWords";
import Chip from "@mui/material/Chip";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { ModalImages } from "../../pages/landing/modalImages/modalImages";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BUSSINES = 1;

const MetaData = ({ data, update, styles, updateStyles, view }) => {
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [msg, sendNotification] = useNotification();
  const [keyword, setKeyword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);

  const [file2, setFile2] = useState();
  const [open2, setOpen2] = useState(false);

  const [file3, setFile3] = useState();
  const [open3, setOpen3] = useState(false);

  const [color1, setColor1] = useState("#ffffff")
  const [color2, setColor2] = useState("#ffffff")

  const [loadingColor, setLoadingColor] = useState(false)

  const Navigate = useNavigate();

  const [business, setBusiness] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  /*   useEffect(() => {
      UpdateBusinessInfo();
    }, []);
  
    const UpdateBusinessInfo = () => {
      (async () => {
        let useBusines = new BusinessManages();
        let response = await useBusines.getAllAuth(logout);
        setBusiness(response.data);
        
      })();
    }; */
  //-----------------------------LOGO DE 462 x 85==============================//

  const changeInput = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const addLogos = (file) => {
    (async () => {
      var formData = new FormData();
      formData.append("imgLogoRect", file);
      let get = new BusinessImages();
      let response = await get.uploadDoc(
        BUSSINES,
        formData,
        logout
      ); /*   uploadImg post*/
      sendNotification(UseerrorOrsuccess(response));
      update();
    })();
  };
  //-----------------------------LOGO DE 64 X 64==============================//

  const addLogos2 = (file) => {
    (async () => {
      var formData = new FormData();
      formData.append("imgFavicon", file);
      let get = new BusinessImages();
      let response = await get.uploadDoc(
        BUSSINES,
        formData,
        logout
      ); /*   uploadImg*/
      sendNotification(UseerrorOrsuccess(response));
      update();
    })();
  };

  const changeInput2 = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile2(e.currentTarget.files[0]);
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen2(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const deleteInput2 = async () => {

    let deleteIcon = new BusinessImages();
    let response = await deleteIcon.deleteAuth(data.favicon, logout);
    update();
    sendNotification(UseerrorOrsuccess(response));
  };


  //-----------------------------LOGO DE 600 x 315==============================//

  const changeInput3 = (e) => {
    console.log(e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));
    if (e.currentTarget.files.length <= 1) {
      setFile3(e.currentTarget.files[0]); //URL.createObjectURL()
      console.log(URL.createObjectURL(e.currentTarget.files[0]));
      setOpen3(true);
      console.log("open");
    } else {
      enqueueSnackbar("Maximo 1 imagen ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const addLogos3 = (file) => {
    (async () => {
      var formData = new FormData();
      formData.append("imgLogoSqr", file);
      let get = new BusinessImages();
      let response = await get.uploadDoc(
        BUSSINES,
        formData,
        logout
      ); /*   uploadImg*/
      sendNotification(UseerrorOrsuccess(response));
      update();
    })();
  };

  /*     useEffect(() => {
      window.localStorage.setItem("menu", menu);
    }, [location]); */

  useEffect(() => {
    UpdateKeyword();
  }, []);

  useEffect(() => {
    setColor1(styles?.color_primary || "#ffffff")

    setColor2(styles?.color_secondary || "#ffffff")

  }, [styles]);

  const UpdateKeyword = () => {
    (async () => {
      let useBusines = new BusinessKeyword();
      let response = await useBusines.getAllAuth(logout);
      setKeyword(response.data);
    })();
  };
  const AddKeyword = (formData) => {
    (async () => {
      if (keyword.length < 5) {
        let useBusines = new BusinessKeyword();
        let response = await useBusines.postDataAuth(formData, logout);
        UpdateKeyword();
      } else {
        enqueueSnackbar("Máximo de keywords alcanzado", {
          variant: "error",
        });
      }
      setLoading(false);
    })();
  };

  // const UpdateAddress = async (id, formData) => {
  //   let updateAddress = new BusinessMetaData();
  //   const response = await updateAddress.editDataAuth(id, formData, logout);
  //   //sendNotification(UseerrorOrsuccess(response));
  //   update();
  // };

  const UpdateInfo = async (formData) => {
    console.log("Ejecutando UpdateInfo");
    let updateTaxt = new BusinessMetaData();
    const response = await updateTaxt.editDataAuth(BUSSINES, formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
    setLoading(false);
  };

  const formikDesc = useFormik({
    initialValues: initValDescription(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDescription()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);
      UpdateInfo(formData);
    },
  });

  const formikChangeAdres = useFormik({
    initialValues: initValDescription(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDescription()),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);
      UpdateInfo(formData);
    },
  });


  const formikKeyWord = useFormik({
    initialValues: initValKeyWord(),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchKeyWord(keyword)),
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      console.log(formData);

      AddKeyword(formData);

      resetForm();
    },
  });


  const handleDelete = async (id) => {
    let useBusines = new BusinessKeyword();
    let response = await useBusines.deleteAuth(id, logout);
    UpdateKeyword();
  };

  const changesColor = async () => {
    setLoadingColor(true)
    const formData = {
      color_primary: color1,
      color_secondary: color2
    }
    let setColor = new BusinessSettingsColors();
    await setColor.postDataAuth(formData, logout);
    const response = await setColor.postDataAuth(formData, logout);

    setLoadingColor(false)
    updateStyles();
    sendNotification(UseerrorOrsuccess(response)); // Muestra la notificación de éxito/error
  };

  //---------------------------FUNCIONES DE LA TIPOGRAFIA-----------------------------//

  const changeFile = (e) => {
    console.log("Esto subiste a changeFile: " + e.currentTarget.files);
    console.log(URL.createObjectURL(e.currentTarget.files[0]));

    if (e.currentTarget.files.length <= 1) {
      //setFile(e.currentTarget.files[0]);
      (async () => {
        try {
          var formData = new FormData();
          formData.append("fileUpload", e.currentTarget.files[0]);
          let get = new BusinessTypography();
          let response = await get.uploadImg(formData, logout); //uploadImg post --- uploadDoc put
          updateStyles();
        } catch (error) {

        }
        //sendNotification(UseerrorOrsuccess(response));

      })();
    } else {
      enqueueSnackbar("Maximo 1 archivo ", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const updateTypography = (e, id) => {
    console.log("Archivo aqui: " + e.currentTarget.files);
    //console.log(URL.createObjectURL(e.currentTarget.files[0]));

    // Verificamos si hay al menos un archivo antes de continuar
    if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
      enqueueSnackbar("No se seleccionó ningún archivo", {
        autoHideDuration: 1000,
        variant: "warning",
      });
      return; // Sale de la función si no hay archivo
    }


    if (e.currentTarget.files.length <= 1) {
      (async () => {
        const formData = new FormData();
        formData.append("fileUpload", e.currentTarget.files[0]);

        // Crear instancia de BusinessTypography y realizar la solicitud PUT
        const updateTypo = new BusinessTypography();
        const response = await updateTypo.editDataAuth(id, formData, logout);

        // Verificar la respuesta y actualizar el estado
        if (response) {
          updateStyles(); // Actualiza los estilos si la respuesta es exitosa
          sendNotification(UseerrorOrsuccess(response)); // Muestra la notificación de éxito/error
        } else {
          sendNotification(UseerrorOrsuccess(response)); // Muestra la notificación de éxito/error
        }
      })();
    } else {
      enqueueSnackbar("Máximo 1 archivo", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };

  const removeTypography = () => {
    if (styles?.typography) {
      (async (id) => {
        try {
          let deleteTypography = new BusinessTypography();
          let response = await deleteTypography.deleteAuth(data.id, logout);
          updateStyles();  //Actualiza el estado después de eliminar
          sendNotification(UseerrorOrsuccess(response));
        } catch (error) {
          enqueueSnackbar("Error eliminando la tipografía", {
            autoHideDuration: 1000,
            variant: "error",
          });
        }
      })();
    } else {
      enqueueSnackbar("No hay tipografía seleccionada", {
        autoHideDuration: 1000,
        variant: "warning",
      });
    }
  };


  // console.log(view)

  return (
    <Box sx={{ marginBottom: "32px" }}>
      {view === "Estilo" && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", width: "100%" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab
                iconPosition="start"
                icon={<TextFormatIcon />}
                label="Tipografía"
                {...a11yProps(0)}
              />
              <Tab
                iconPosition="start"
                icon={<FormatColorFillOutlinedIcon />}
                label="Colores"
                {...a11yProps(1)}
              />
              <Tab
                iconPosition="start"
                icon={<FilterOutlinedIcon />}
                label="Iconos/logos"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid container>
              <Grid item xs={12} sx={{ paddingTop: "20px" }} >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Tipografía</Typography>
                <Typography variant="body1" mt={"8px"} mb={"8px"} >
                  Puedes explorar más tipografías en{" "}
                  <a href="https://fonts.google.com/" target="_blank">
                    Google fonts
                  </a>
                  , sube el archivo tipo <strong>otf</strong> o <strong>ttf</strong>{" "}
                  en <strong>regular 400</strong>
                </Typography>
                <Autocomplete
                  fullWidth
                  freeSolo
                  options={styles?.typography ? [styles.typography] : []}
                  defaultValue={styles?.typography ? styles?.typography : ""} // Muestra la tipografía actual si existe
                  sx={{
                    padding: "8px 0",
                  }}
                  renderInput={(params) => <TextField {...params} label="" placeholder="" />}
                />



                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end"
                  }}
                >
                  {/*{styles && (
                    <Typography ml={"8px"}>
                      {styles?.typography ? styles?.typography : ""}
                    </Typography>
                  )}*/}
                  <br />
                  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 1 }}>
                    <label>
                      <input
                        hidden
                        type="file"
                        accept=".ttf, .otf"
                        onChange={changeFile}
                      />
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{ borderRadius: "20px", textTransform: "none", display: "flex", alignItems: "center" }}
                      >
                        <ImageOutlinedIcon sx={{ mr: 1 }} />
                        Cargar Tipografía
                      </Button>
                    </label>


                    {styles?.typography && (
                      <Button
                        onClick={removeTypography}
                        variant="text"
                        color="inherit"
                        sx={{ color: 'gray', borderRadius: "20px", display: "flex", alignItems: "center" }}
                      // onClick={removeTypography}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                  </Box>
                </Box>

                <Divider sx={{ mt: "35px" }} />
                {/* {styles?.typography && (
                  <Box mt={2} sx={{ border: "1px solid #ddd", padding: "16px" }}>
                    <Typography variant="h6">Vista previa de la tipografía:</Typography>
                    <Typography sx={{ fontFamily: styles.typography }}>
                      Esto es una vista previa del texto en la tipografía seleccionada.
                    </Typography>
                  </Box>
                )}*/}
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 6 }}>
                  <Button
                    onClick={updateTypography}
                    variant="contained"
                    component="span"
                    sx={{ borderRadius: "20px", textTransform: "none", display: "flex", alignItems: "center", fontSize: '1em' }}
                  >
                    Actualizar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {styles && <><Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
              <Typography variant="h6" marginBottom={"20px"} sx={{ fontWeight: 'bold' }} >Colores principal</Typography>
              <TextField
                label="Color principal"
                size="medium"
                name="bg_color1"
                value={color1}
                onChange={(e) => setColor1(e.target.value)}
                sx={{ width: "200px", marginRight: "8px", marginBottom: "8px" }}
                type="color" />
              <Typography variant="h6" marginBottom={"20px"} sx={{ fontWeight: 'bold' }}>Colores secundario</Typography>
              <TextField
                label="Color secundario"
                size="medium"
                name="bg_color2"
                value={color2}
                onChange={(e) => setColor2(e.target.value)}
                sx={{ width: "200px", }}
                type="color" />
              <br />

              <Divider sx={{ marginTop: "35px" }} />
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 6 }}>
                <LoadingButton
                  loading={loadingColor}
                  onClick={() => changesColor()}
                  variant="contained"
                  component="span"
                  sx={{ borderRadius: "20px", textTransform: "none", display: "flex", alignItems: "center", fontSize: '1em', mt: "5px" }}
                >
                  Actualizar
                </LoadingButton>
              </Box>
            </Box>
            </>}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Grid container>
              <Grid item xs={12} sx={{ paddingTop: "20px", flexDirection: "column" }}>
                <Typography sx={{ fontSize: '1em', fontWeight: 'bold' }}>Icono</Typography>
                <Typography sx={{ fontSize: '1em', pt: '1em' }}>La imagen seleccionada debe ser de tamaño de 64 x 64</Typography>

                {data?.favicon && (
                  <img src={data.favicon} style={{ margin: "0px 8px", width: 64, height: 64, objectFit: "fill", marginTop: '15px' }} />
                )}
                {!data.favicon && (
                  <Box
                    sx={{
                      width: "64px",
                      height: "64px",
                      border: "2px dashed #000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    64 x 64
                  </Box>
                )}



                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '3px solid',
                    borderColor: 'grey.300',
                    padding: 2,
                    borderRadius: 1
                  }}
                >

                  <IconButton color="gray" onClick={deleteInput2}>
                    <DeleteOutlineIcon />
                  </IconButton>


                  <label htmlFor="upload-button-file" style={{ margin: 0 }}>
                    <input
                      hidden
                      id="upload-button-file"
                      type="file"
                      multiple
                      onChange={changeInput2}
                    />
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      component="span"
                      sx={{ borderRadius: '1em' }}
                    >
                      Subir
                    </LoadingButton>
                  </label>
                </Box>

                {file2 && open2 && (
                  <ImgEdit
                    open={open2}
                    handleClose={setOpen2}
                    file={file2}
                    size={{ height: 64, width: 64 }}
                    update={addLogos2}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ paddingTop: "40px", flexDirection: "column" }}
              >

                <Typography sx={{ fontSize: '1em', fontWeight: 'bold' }}>Logotipo</Typography>
                <Typography sx={{ fontSize: '1em', pt: '1em' }}>La imagen seleccionada debe ser de tamaño de 462 x 85</Typography>

                <Box sx={{ display: 'inline-flex', mt: '1em' }}>
                  <Card sx={{
                    maxWidth: '565px',
                    height: 'auto',
                  }}>
                    {data?.logo_rect && (
                      <CardMedia
                        component="img"
                        alt="Logo"
                        image={data.logo_rect}
                        sx={{
                          width: '565px',
                          height: 'auto',
                        }}
                      />
                    )}

                    {/* Contenido del Card */}
                    <CardContent sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid',
                      borderColor: 'grey.300',
                      padding: 2,
                      borderRadius: 1
                    }}>
                      <IconButton color="gray">
                        <DeleteOutlineIcon />
                      </IconButton>


                      <label htmlFor="upload-button-file" style={{ margin: 0 }}>
                        <input
                          hidden
                          id="upload-button-file"
                          type="file"
                          multiple
                          onChange={changeInput}
                        />
                        <LoadingButton
                          variant="outlined"
                          color="primary"
                          component="span"
                          sx={{ borderRadius: '1em' }}
                        >
                          Subir
                        </LoadingButton>
                      </label>
                    </CardContent>
                  </Card>
                </Box>

                {!data.logo_rect && (
                  <Box
                    sx={{
                      width: "254px",
                      height: "60px",
                      border: "2px dashed #000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    462 x 85
                  </Box>
                )}

                {/*
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label>
                    <Input
                      hidden
                      sx={{ display: "none" }}
                      type="file"
                      multiple
                      onChange={changeInput}
                    ></Input>
                    <Button
                    

                      component="span"
                    >
                      Subir logotipo
                    </Button>
                  </label>
                  <Typography>462 x 85</Typography>
                </Box>
                  */}

                {file && open && (
                  <ImgEdit
                    open={open}
                    handleClose={setOpen}
                    file={file}
                    size={{ height: 85, width: 462 }}
                    update={addLogos}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ paddingTop: "40px", flexDirection: "column" }}
              >
                <Typography sx={{ fontSize: '1em', fontWeight: 'bold' }}>Imagen destacada</Typography>
                <Typography sx={{ fontSize: '1em', pt: '1em' }}>La imagen seleccionada debe ser de tamaño de 600 x 315</Typography>
                <Box sx={{ display: 'inline-flex', mt: '1em' }}>
                  <Card sx={{
                    maxWidth: '565px',
                    height: 'auto',
                  }}>
                    {data?.logo_sqr && (
                      <CardMedia
                        component="img"
                        alt="Logo"
                        image={data.logo_sqr}
                        sx={{
                          width: '565px',
                          height: 'auto',
                        }}
                      />
                    )}

                    {/* Contenido del Card */}
                    <CardContent sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid',
                      borderColor: 'grey.300',
                      padding: 2,
                      borderRadius: 1
                    }}>
                      <IconButton color="gray">
                        <DeleteOutlineIcon />
                      </IconButton>


                      <label htmlFor="upload-button-file" style={{ margin: 0 }}>
                        <input
                          hidden
                          id="upload-button-file"
                          type="file"
                          multiple
                          onChange={changeInput3}
                        />
                        <LoadingButton
                          variant="outlined"
                          color="primary"
                          component="span"
                          sx={{ borderRadius: '1em' }}
                        >
                          Subir
                        </LoadingButton>
                      </label>
                    </CardContent>
                  </Card>
                </Box>

                {!data.logo_sqr && (
                  <Box
                    sx={{
                      width: "256px",
                      height: "157px",
                      border: "2px dashed #000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    600 x 315
                  </Box>
                )}

                {/*}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label>
                    <Input
                      hidden
                      sx={{ display: "none" }}
                      type="file"
                      multiple
                      onChange={changeInput3}
                    ></Input>
                    <Button

                      component="span"
                    >
                      Subir imagen destacada
                    </Button>
                  </label>
                  <Typography>600 x 315</Typography>
                </Box>
              */}



                {file3 && open3 && (
                  <ImgEdit
                    open={open3}
                    handleClose={setOpen3}
                    file={file3}
                    size={{ height: 315, width: 600 }}
                    update={addLogos3}
                  />
                )}
              </Grid>
            </Grid >
          </CustomTabPanel >
        </Box >
      )
      }
      {
        view === "Metadata" && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", width: "100%" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab
                  iconPosition="start"
                  icon={<TableRowsOutlinedIcon />}
                  label="Metadata"
                  sx={{ textTransform: 'none', fontSize: '1em' }}
                  {...a11yProps(0)}
                />
                <Tab
                  iconPosition="start"
                  icon={<MapOutlinedIcon />}
                  label="Direccion"
                  sx={{ textTransform: 'none', fontSize: '1em' }}
                  {...a11yProps(1)}
                />
                <Tab
                  iconPosition="start"
                  icon={<PeopleAltOutlinedIcon />}
                  label="Redes"
                  sx={{ textTransform: 'none', fontSize: '1em' }}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid item xs={12} sx={{ paddingTop: "20px" }} >
                <Typography variant="body1" sx={{ color: "", fontWeight: 'bold', marginBottom: "20px" }}>
                  Metadata
                </Typography>
                <Typography variant="body1" >
                  Actualiza la siguiente información para mejorar el posicionamiento de tu página en el buscador.
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={formikDesc.handleSubmit}>
                  <Grid container>
                    <Grid item xs={12} sx={{ marginTop: "16px" }}>
                      <TextField
                        fullWidth
                        name="description"
                        multiline
                        label="Descripción"
                        size="small"
                        value={formikDesc.values.description}
                        onChange={formikDesc.handleChange}
                        inputProps={{
                          maxLength: 160,
                        }}
                        InputProps={{
                          maxLength: 160,
                          endAdornment: (
                            <InputAdornment position="end">
                              {formikDesc.values.description.length}/160
                            </InputAdornment>
                          ),
                        }}
                        error={
                          formikDesc.touched.description &&
                          Boolean(formikDesc.errors.description)
                        }
                        helperText={
                          formikDesc.touched.description
                            ? formikDesc.errors.description
                            : ""
                        }
                      />

                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }} >
                      <LoadingButton loading={loading} type="submit" variant="contained" sx={{ borderRadius: '5em', mt: '1em' }}>
                        Actualizar
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Divider sx={{ mt: "35px" }} />
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ color: "", fontWeight: 'bold', marginTop: "20px" }}>
                  Keywords
                </Typography>
                <Typography variant="body1" sx={{ mt: '1em' }} >
                  Los keywords(palabras clave) ayudan a encontrar tu sitio web cuando alguien busca esas palabras en el navegador.
                  <br />
                </Typography>
                <form onSubmit={formikKeyWord.handleSubmit}>
                  <Grid container>
                    <Grid item xs={12} sx={{ marginTop: "16px" }}>
                      <TextField
                        fullWidth
                        name="name"
                        multiline
                        label="Keyword"
                        size="small"
                        value={formikKeyWord.values.name}
                        onChange={formikKeyWord.handleChange}
                        InputProps={{
                          maxLength: 160,
                        }}
                        error={
                          formikKeyWord.touched.name &&
                          Boolean(formikKeyWord.errors.name)
                        }
                        helperText={
                          formikKeyWord.touched.name ? formikKeyWord.errors.name : ""
                        }
                      />
                    </Grid>

                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "8px" }}>
                {keyword && (
                  <Stack direction="row" spacing={1}>
                    {keyword.map((data, index) => (
                      <Chip
                        key={index}
                        label={data.name}
                        onDelete={() => handleDelete(data.id)}
                      />
                    ))}
                  </Stack>
                )}
                <CountWords length={keyword.length} max="5" />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }} >
                <LoadingButton loading={loading} type="submit" variant="contained" sx={{ borderRadius: '5em', mt: '2em' }}>
                  Agregar
                </LoadingButton>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>

              <form onSubmit={formikChangeAdres.handleSubmit}>
                <Grid container spacing={1} >
                  <Grid item xs={12} sx={{ paddingTop: "20px", }} >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: "10px", marginTop: "20px" }}>
                      Dirección principal
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "20px", }} >
                    <Typography sx={{ marginBottom: "10px", marginTop: "20px" }}>
                      Dirección
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="street"
                      label="Calle"
                      margin="dense"
                      size="small"
                      variant="outlined"
                      defaultValue={data?.address?.street}
                      value={formikChangeAdres.values.street}
                      onChange={formikChangeAdres.handleChange}
                      inputProps={{
                        maxLength: 30,
                      }}
                      InputProps={{
                        maxLength: 30,
                        endAdornment: (
                          <InputAdornment position="end">
                            {(formikChangeAdres.values.street || "").length}/30
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formikChangeAdres.touched.street && Boolean(formikChangeAdres.errors.street)
                      }
                      helperText={
                        formikChangeAdres.touched.street ? formikChangeAdres.errors.street : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="ext_number"
                      label="Número exterior"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.ext_number}
                      value={formikChangeAdres.values.ext_number}
                      onChange={formikChangeAdres.handleChange}
                      error={
                        formikDesc.touched.ext_number && Boolean(formikChangeAdres.errors.ext_number)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.ext_number ? formikChangeAdres.errors.ext_number : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="int_number"
                      label="Número interior"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.int_number}
                      value={formikChangeAdres.values.int_number}
                      onChange={formikChangeAdres.handleChange}
                      error={
                        formikChangeAdres.touched.int_number && Boolean(formikChangeAdres.errors.int_number)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.int_number ? formikChangeAdres.errors.int_number : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="postal_code"
                      label="Código Postal"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.postal_code}
                      value={formikChangeAdres.values.postal_code}
                      onChange={formikChangeAdres.handleChange}
                      error={
                        formikChangeAdres.touched.postal_code && Boolean(formikChangeAdres.errors.postal_code)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.postal_code ? formikChangeAdres.errors.postal_code : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="state"
                      label="Estado"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.state}
                      value={formikChangeAdres.values.state}
                      onChange={formikChangeAdres.handleChange}
                      error={
                        formikChangeAdres.touched.state && Boolean(formikChangeAdres.errors.state)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.state ? formikChangeAdres.errors.state : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="city"
                      label="Ciudad/Municipio"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.city}
                      value={formikChangeAdres.values.city}
                      onChange={formikChangeAdres.handleChange}
                      error={
                        formikChangeAdres.touched.city && Boolean(formikChangeAdres.errors.city)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.city ? formikChangeAdres.errors.city : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="suburb"
                      label="Colonia"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.suburb}
                      value={formikChangeAdres.values.suburb}
                      onChange={formikChangeAdres.handleChange}
                      error={
                        formikChangeAdres.touched.suburb && Boolean(formikChangeAdres.errors.suburb)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.suburb ? formikChangeAdres.errors.suburb : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="intercross"
                      label="Calles que cruzan"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.intercross}
                      value={formikChangeAdres.values.intercross}
                      onChange={formikChangeAdres.handleChange}
                      inputProps={{
                        maxLength: 30,
                      }}
                      InputProps={{
                        maxLength: 30,
                        endAdornment: (
                          <InputAdornment position="end">
                            {(formikChangeAdres.values.intercross || "").length}/30
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formikChangeAdres.touched.intercross && Boolean(formikChangeAdres.errors.intercross)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.intercross ? formikChangeAdres.errors.intercross : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "20px" }} >
                    <TextField
                      fullWidth
                      name="phone"
                      label="Teléfono"
                      margin="dense"
                      size="small"
                      defaultValue={data?.address?.phone}
                      value={formikChangeAdres.values.phone}
                      onChange={formikChangeAdres.handleChange}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        maxLength: 10,
                        endAdornment: (
                          <InputAdornment position="end">
                            {(formikChangeAdres.values.phone || "").length}/10
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formikChangeAdres.touched.phone && Boolean(formikChangeAdres.errors.phone)
                      }
                      variant="outlined"
                      helperText={
                        formikChangeAdres.touched.phone ? formikChangeAdres.errors.phone : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: "1em", display: "flex", justifyContent: "right" }} >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={{ borderRadius: "20px", textTransform: "none", alignItems: "center", fontSize: '1em' }}
                      loading={loading}
                    >
                      Actualizar
                    </LoadingButton>


                  </Grid>
                </Grid>
              </form>

              {/* <Grid item xs={12} mt="32px">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {data?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} mt={"6px"}>
          <Stack spacing={"8px"}>
            <Typography variant="body1">
              {`${data?.address?.street ? data?.address?.street : ""} #${data?.address?.ext_number ? data?.address?.ext_number : ""
                }`}
            </Typography>
            <Typography variant="body1">
              {`${data?.address?.suburb ? data?.address?.suburb : ""}, C.P. ${data?.address?.postal_code ? data?.address?.postal_code : ""
                },`}
            </Typography>
            <Typography variant="body1">
              {`${data?.address?.city ? data?.address?.city : ""}, ${data?.address?.state ? data?.address?.state : ""
                }`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} mt={"6px"}>
          <Stack spacing={"8px"}>
            <Typography variant="body1">
              {`${data?.address?.phone} `}
            </Typography>
            <Typography variant="body1">{`${data?.email}`}</Typography>
          </Stack>
        </Grid> */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Grid item xs={12} mt={"32px"}>
                <SocialMedia data={data.social_networks} UpdateInfo={update} />
              </Grid>
            </CustomTabPanel>
          </Box>
        )
      }

      <Grid container>
        <Grid item xs={12} mt="16px">
          {
            view === "Libreria" && (
              <>
                <ModalImages data={data.library} UpdateInfo={update} />
              </>
            )}
          {/*    <Typography variant='body1' sx={{color: "text.secondary"}}>
                    La siguiente información se puede editar desde la app de Administración, mantén tu información actualizada, ya que esta se mostrara en tu página de aterrizaje.
                    </Typography> */}
          {/*   <Grid item xs={12} mt={"16px"}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">METADATA</Typography>
           {/*    <Button
                onClick={() => {
                  window.localStorage.setItem("menu", 1);
                  Navigate("/administration/business");
                }}
              >
                Actualizar
              </Button> 
            </Box>
            <Divider
              sx={{ marginBottom: "16px", backgroundColor: "text.secondary" }}
            />
          </Grid> */}
          {/* <GenericHeaderSubTitle title="METADATA" /> */}
        </Grid>
        {/* <Grid item xs={12} mt="32px">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {data?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} mt={"6px"}>
          <Stack spacing={"8px"}>
            <Typography variant="body1">
              {`${data?.address?.street ? data?.address?.street : ""} #${data?.address?.ext_number ? data?.address?.ext_number : ""
                }`}
            </Typography>
            <Typography variant="body1">
              {`${data?.address?.suburb ? data?.address?.suburb : ""}, C.P. ${data?.address?.postal_code ? data?.address?.postal_code : ""
                },`}
            </Typography>
            <Typography variant="body1">
              {`${data?.address?.city ? data?.address?.city : ""}, ${data?.address?.state ? data?.address?.state : ""
                }`}
            </Typography>
          </Stack>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} mt={"6px"}>
          <Stack spacing={"8px"}>
            <Typography variant="body1">
              {`${data?.address?.phone} `}
            </Typography>
            <Typography variant="body1">{`${data?.email}`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} mt={"32px"}>
          <SocialMedia data={data.social_networks} UpdateInfo={update} />
        </Grid> */}

        {/*   <Grid item xs={12} mt={"32px"}>
          <Grid container spacing={"12px"}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="Facebook"
                size="small"
                value={data?.social_networks?.facebook}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="YouTube"
                size="small"
                value={data?.social_networks?.youtube}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="Instagram"
                size="small"
                value={data?.social_networks?.instagram}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="LinkedIn"
                size="small"
                value={data?.social_networks?.linkedin}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="WhatsApp"
                size="small"
                value={data?.social_networks?.whatsapp}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={true}
                label="Tik Tok"
                size="small"
         
                value={data?.social_networks?.tiktok}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Divider
            sx={{
              marginTop: "32px",
              marginBottom: "16px",
              backgroundColor: "text.secondary",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Actualiza la siguiente información para mejorar el posicionamiento
            de tu página.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formikDesc.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={10} sx={{ marginTop: "16px" }}>
                <TextField
                  fullWidth
                  name="description"
                  multiline
                  label="Descripción breve de tu negocio"
                  size="small"
                  value={formikDesc.values.description}
                  onChange={formikDesc.handleChange}
                  InputProps={{
                    maxLength: 160,
                  }}
                  error={
                    formikDesc.touched.description &&
                    Boolean(formikDesc.errors.description)
                  }
                  helperText={
                    formikDesc.touched.description
                      ? formikDesc.errors.description
                      : ""
                  }
                />
                <CountWords
                  length={formikDesc.values.description.length}
                  max="160"
                />
              </Grid>
              <Grid item xs={12} sm={2} sx={{ marginTop: "16px" }}>
                <LoadingButton loading={loading} fullWidth type="submit">
                  Actualizar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid> */}

        {/* <Grid item xs={12}>
          <form onSubmit={formikKeyWord.handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={10} sx={{ marginTop: "16px" }}>
                <TextField
                  fullWidth
                  name="name"
                  multiline
                  label="Keyword"
                  size="small"
                  value={formikKeyWord.values.name}
                  onChange={formikKeyWord.handleChange}
                  InputProps={{
                    maxLength: 160,
                  }}
                  error={
                    formikKeyWord.touched.name &&
                    Boolean(formikKeyWord.errors.name)
                  }
                  helperText={
                    formikKeyWord.touched.name ? formikKeyWord.errors.name : ""
              <Grid item xs={12} sm={2} sx={{ marginTop: "16px" }}>
                <LoadingButton loading={loading} fullWidth type="submit">
                  Agregar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid> */}
        {/* <Grid item xs={12} sx={{ marginTop: "8px" }}>
          {keyword && (
            <Stack direction="row" spacing={1}>
              {keyword.map((data, index) => (
                <Chip
                  key={index}
                  label={data.name}
                  onDelete={() => handleDelete(data.id)}
                />
              ))}
            </Stack>
          )}
          <CountWords length={keyword.length} max="5" />
        </Grid> */}

        <Grid item xs={12} mt={"16px"}>
          {/*      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Estilo</Typography>
          </Box>
          <Divider
            sx={{ marginBottom: "16px", backgroundColor: "text.secondary" }}
          /> */}
          {/* <GenericHeaderSubTitle title="Estilo" /> */}
        </Grid>


        <Grid item xs={12}>
          {/* <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <label style={{}}>
              <input
                hidden
                sx={{ display: "none" }}
                type="file"
                accept=".ttf, .otf "
                onChange={changeFile}
              ></input>
              <Button variant="contained" component="span">
                Tipografía
              </Button>
            </label>{" "}
            {styles && <Typography ml={"8px"}> {styles?.typography ? styles?.typography : ""} </Typography>}
          </Box>
          <Typography variant="body1" mt={"8px"}>
            Puedes explorar más tipografías en{" "}
            <a href="https://fonts.google.com/" target="_blank">
              Google fonts
            </a>
            , sube el archivo tipo <strong>otf</strong> o <strong>ttf</strong>{" "}
            en <strong>regular 400</strong>
          </Typography> */}
          {/* {styles && <Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
            <TextField
              label="Color principal"
              size="small"
              name="bg_color1"
              value={color1}
              onChange={(e) => setColor1(e.target.value)}
              sx={{ width: "100px", marginRight: "8px" }}
              type="color" />


            <TextField
              label="Color secundario"
              size="small"
              name="bg_color2"
              value={color2}
              onChange={(e) => setColor2(e.target.value)}
              sx={{ width: "100px" }}
              type="color" />
            <LoadingButton loading={loadingColor} onClick={() => changesColor()} sx={{ marginLeft: "8px" }}>Guardar</LoadingButton>
          </Box>} */}

        </Grid>

        <Grid item xs={12} mt={"16px"}>
          {/*   <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Recursos Graficos</Typography>
          </Box>
          <Divider
            sx={{ marginBottom: "16px", backgroundColor: "text.secondary" }}
          /> */}
          {/* <GenericHeaderSubTitle title="Recursos Gráficos" /> */}
        </Grid>


      </Grid>
    </Box >
  );
};

export default MetaData;
