import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Link, Paper } from "@mui/material";
import SalesOrder from "../../../api/salesOrder";
import PurchaseService from "../../../api/purchaseService";
import { useAuth } from "user-context-fran-dev";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArticlesListPreview from "../../../components/inventario/tables/ArticlesListPreview";

const TableInfo = ({ data, HandleGo, is_purchase, open }) => {
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();

  useEffect(() => {
    if (!open) {
      return;
    }
    if (is_purchase) {
      GetInfoPurchase();
    } else {
      GetInfoSale();
    }
  }, [open]);

  const GetInfoSale = () => {
    (async () => {
      setLoading(true);
      let getSales = new SalesOrder();
      let response = await getSales.getAllByIdAuth(data.id, logout);
      setArticle(response.data);
      setLoading(false);
    })();
  };

  const GetInfoPurchase = () => {
    (async () => {
      setLoading(true);
      let getPurchase = new PurchaseService();
      let response = await getPurchase.getAllByIdAuth(data.id, logout);
      setArticle(response.data);
      setLoading(false);
    })();
  };

  return (
    <>
      {!article && loading && (
        <Grid container justifyContent="center" p={"24px"}>
          <CircularProgress />
        </Grid>
      )}
      {article && (
        <Grid container spacing={2} p={1}>
          <Grid item xs={6} mb={1}>
            <Typography variant="h6" gutterBottom component="div">
              {is_purchase ? "Ordenado" : "Reservado"}
            </Typography>
          </Grid>
          <Grid item xs={6} mb={1}>
            <Grid container justifyContent="flex-end">
              <Link
                sx={{ cursor: "pointer" }}
                underline="none"
                onClick={() => {
                  HandleGo(article.id);
                }}
              >
                <Typography variant="h6" gutterBottom component="div">
                  Editar
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              className="space-text-field"
              label="Condiciones de pago"
              defaultValue={
                article.pay_conditions ? article.pay_conditions : ""
              }
              InputProps={{
                readOnly: true,
              }}
              focused={false}
              sx={{ margin: "6px" }}
            />
            <TextField
              fullWidth
              id="outlined-read-only-input"
              label="Días de crédito"
              defaultValue={article.credit_days ? article.credit_days : ""}
              InputProps={{
                readOnly: true,
              }}
              focused={false}
              sx={{ margin: "6px" }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              className="space-text-field"
              label="Método de pago"
              defaultValue={article.pay_method}
              InputProps={{
                readOnly: true,
              }}
              focused={false}
              sx={{ margin: "6px" }}
            />
            <TextField
              fullWidth
              id="outlined-read-only-input"
              label="IVA"
              defaultValue={article.iva ? article.iva : ""}
              InputProps={{
                readOnly: true,
              }}
              focused={false}
              sx={{ margin: "6px" }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="filled-multiline-flexible"
              label="Comentarios"
              multiline
              maxRows={4}
              defaultValue={article.comments ? article.comments : ""}
              InputProps={{
                readOnly: true,
              }}
              focused={false}
              sx={{
                margin: "6px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ArticlesListPreview data={article}></ArticlesListPreview>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const TableArticleInfo = ({ row, name }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <br />
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              elevation={2}
              sx={{ backgroundColor: "background.default", padding: "10px" }}
            >
              <Grid container>
                <Grid item xs={open ? 3 : 1} sx={{ transition: "1s" }}>
                  <img
                    src={row.model.image ? row.model.image.url : ""}
                    style={
                      !open
                        ? {
                            width: "70%",
                            height: "auto",
                            marginBottom: "-6px",
                            padding: "0px",
                            transition: "1s",
                          }
                        : {
                            width: "60%",
                            height: "auto",
                            marginBottom: "-6px",
                            padding: "0px",
                            transition: "1s",
                          }
                    }
                  ></img>
                </Grid>

                <Grid item xs={open ? 9 : 11} sx={{ transition: "1s" }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ m: 0, p: 0 }}
                      >
                        {row.model.product.name}
                      </Typography>

                      {open && (
                        <Typography
                          variant="subtitle2"
                          component="div"
                          sx={{ m: 0, p: 0 }}
                        >
                          {row.model.product.sku}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <IconButton
                          sx={{ m: 0, p: 0 }}
                          onClick={() => {
                            setOpen(!open);
                          }}
                        >
                          {open ? (
                            <KeyboardArrowUpIcon
                              fontSize="small"
                              sx={{ color: "text.primary" }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              sx={{ color: "text.primary" }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <Collapse in={open} timeout={1000} unmountOnExit>
                        <Grid container p={2} spacing={2}> 
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="outlined-read-only-input"
                              label="Modelo"
                              defaultValue={row.model.name}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ margin: "6px" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="outlined-read-only-input"
                              label="Presentación"
                              defaultValue={row.presentation.name}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ margin: "6px" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="outlined-read-only-input"
                              label="Restante"
                              defaultValue={row.quantity}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ margin: "6px" }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              fullWidth
                              id="outlined-read-only-input"
                              label="Pendiente"
                              defaultValue={row.quantity_pending}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ margin: "6px" }}
                            />
                        </Grid>
                      </Grid>
                    </Collapse> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/*          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container p={2} spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="outlined-read-only-input"
                    label="Modelo"
                    defaultValue={row.model.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ margin: "6px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="outlined-read-only-input"
                    label="Presentación"
                    defaultValue={row.presentation.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ margin: "6px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="outlined-read-only-input"
                    label="Restante"
                    defaultValue={row.quantity}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ margin: "6px" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="outlined-read-only-input"
                    label="Pendiente"
                    defaultValue={row.quantity_pending}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ margin: "6px" }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
};

export default TableInfo;
