import { Grid, Typography } from "@mui/material";
import React from "react";
import { DateFormat2 } from "../../components/DateFormat";

const HeaderOrder = ({ type, data }) => {
  if (type === "purchase" || type === "sales") {
    return (
      <>
        <PurchaseHeader type={type} data={data} />
      </>
    );
  } else if (type === "order_store") {
    return (
      <>
        <OrderStoreHeader data={data} />
      </>
    );
  } else {
    return <></>;
  }
};

const PurchaseHeader = ({ type, data }) => {
  const isPurchase = type === "purchase";
  return (
    <Grid container>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" component="div">
              <strong>{data?.business?.business_name}</strong>
            </Typography>
            <strong>{data?.business?.rfc}</strong>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" component="div">
              {data?.business?.address &&
                `${data?.business?.address?.street} #${data?.business?.address?.ext_number}, ${data?.business?.address?.suburb}, ${data?.business?.address?.postal_code} `}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" component="div">
              {data?.business?.address &&
                `${data?.business?.address?.city}, ${data?.business?.address?.state}`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" component="div">
              {`Email: ${data?.business?.email}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="div">
              {`Teléfono: ${data?.business?.phone}`}
            </Typography>
          </Grid>
          {/*     <Grid item xs={12}>
              <Typography variant="subtitle1" component="div">
                RFC: ASDF32145DF
              </Typography>
            </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Typography variant="h5" component="div">
                  <strong>
                    {isPurchase ? "Orden de Compra" : "Orden de Venta"}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Typography
                  variant="h7"
                  sx={{ color: "text.green" }}
                  gutterBottom
                  component="div"
                >
                  <strong>{`${data.user.first_name} ${data.user.last_name}`}</strong>
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={4} md={7}>
                <Grid container justifyContent="flex-end">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ textAlign: "right" }}
                  >
                    Num. de orden:
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={8} md={5}>
                <Grid container justifyContent="flex-end">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ textAlign: "right" }}
                  >
                    {data.no_order}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={4} md={7}>
                <Grid container justifyContent="flex-end">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ textAlign: "right" }}
                  >
                    Fecha:
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={8} md={5}>
                <Grid container justifyContent="flex-end">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ textAlign: "right" }}
                  >
                    <Dia text={data.date_created} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} md={7}>
                <Grid container justifyContent="flex-end">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ textAlign: "right" }}
                  >
                    Almacén:
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={8} md={5}>
                <Grid container justifyContent="flex-end">
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ textAlign: "right" }}
                  >
                    {data.warehouse.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const OrderStoreHeader = ({ data }) => {
  const styleStatusOrder = {
    display: "flex",
    flexDirection: "column",
    minWidth: "150px",
    maxWidth: "30vw",
    flexGrow: 1,
    alignItems: "center",
    padding: "8px 0",
    height: "64px",
    borderRadius: "5px",
    justifyContent: "center",
    //backgroundColor: "#fff",
    /*  backgroundColor: "#E8e8e7", */
    border: "1px dashed rgba(0, 0, 0, 0.54)",
  };
  return (
    <Grid container>
      {/*<Grid container  sx={{ gap: "8px" }} fontWeight="bold">
          <Grid
            item
            sx={styleStatusOrder}
            backgroundColor={data.status === 2 ? "#E8e8e7" : data.status > 2 ? "#fff" : ""}
          >
            <Typography variant="body1" display="div">
              <strong>Pedido</strong>
            </Typography>

            {data.status > 2 && <Typography variant="body1" display="div">
              {data?.date_processed && <DateFormat2 beginDate={data.date_processed} />}
            </Typography>}
          </Grid>
          {data.status === 7 && data.date_confirmed === null &&
            <Grid
              item
              xs={3.8}
              md={2.3}
              sx={styleStatusOrder}
              backgroundColor={data.status === 7 ? "#E8e8e7" : "#fff"}
            >
              <Typography variant="body1" display="div">
                <strong>Cancelado</strong>
              </Typography>
              <Typography variant="body1" display="div">
                <DateFormat2 beginDate={data.date_cancel} />

              </Typography>
            </Grid>}
          <Grid
            item
            sx={styleStatusOrder}
            backgroundColor={data.status === 3 ? "#E8e8e7" : data.status > 3 ? "#fff" : ""}
          >
            <Typography variant="body1" display="div">
              <strong>Confirmado</strong>
            </Typography>
            {data.status > 3 && <Typography variant="body1" display="div">


              {data.date_confirmed && <DateFormat2 beginDate={data?.date_confirmed} />}
            </Typography>}
          </Grid>

          {data.status === 7 && data.date_confirmed !== null && data.date_shipping === null &&
            <Grid
              item
              xs={3.8}
              md={2.3}
              sx={styleStatusOrder}
              backgroundColor={data.status === 7 ? "#E8e8e7" : "#fff"}
            >
              <Typography variant="body1" display="div">
                <strong>Cancelado</strong>
              </Typography>
              <Typography variant="body1" display="div">

                <DateFormat2 beginDate={data.date_cancel} />
              </Typography>
            </Grid>}
          <Grid
            item
            sx={styleStatusOrder}

            backgroundColor={data.status === 4 ? "#E8e8e7" : data.status > 4 ? "#fff" : ""}
          >
            <Typography variant="body1" display="div">
              <strong>Enviar</strong>
            </Typography>
            {data.status > 4 && data.date_shipping && <Typography variant="body1" display="div">

              <DateFormat2 beginDate={data.date_shipping} />

            </Typography>}
          </Grid>
          {data.status !== 7 &&
            <Grid
              item sx={styleStatusOrder}
              backgroundColor={data.status === 5 ? "#E8e8e7" : data.status > 5 ? "#fff" : ""}
            >
              <Typography variant="body1" display="div">
                <strong>Por entregar</strong>
              </Typography>
              {data.status >= 5 && data?.date_delivery && <Typography variant="body1" display="div">
                <DateFormat2 beginDate={data.date_delivery} />
              </Typography>}
            </Grid>}
      </Grid>*/}
      <Grid item xs={12}>
        <Grid container marginTop="33px" marginBottom="70px">
          <Grid item xs={4.5}>
            <Typography variant="h6" fontWeight="bold">
              Datos del Cliente
            </Typography>
            <Typography variant="body2" marginTop="15px">
              Nombre:{" "}
              <b>
                {data.user?.first_name} {data.user?.last_name}{" "}
              </b>
            </Typography>
            <Typography variant="body2" marginTop="10px">
              Email:<b> {data.user?.email} </b>
            </Typography>
            <Typography variant="body2" marginTop="10px">
              Teléfono:<b> {data.user?.phone} </b>
            </Typography>
          </Grid>

          <Grid item xs={4.5}>
            <Typography variant="h6" fontWeight="bold">
              {" "}
              Dirección de envío
            </Typography>
            <Typography variant="body2" marginTop="15px">
              {data.delivery_address?.street} #
              {data.delivery_address?.ext_number}
            </Typography>
            <Typography variant="body2" marginTop="10px">
              {data.delivery_address?.suburb},{" "}
              {data.delivery_address?.postal_code}
            </Typography>
            <Typography variant="body2" marginTop="10px">
              {data.delivery_address?.city}, {data.delivery_address?.state}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Dia = ({ text }) => {
  /*  console.log("fecha en warehouse", text); */
  const Month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const dia = new Date(text);
  return (
    <Typography variant="h7" component="div">
      {`${Month[dia.getMonth()]} ${dia.getDate()}, ${dia.getFullYear()}`}
    </Typography>
  );
};

export default HeaderOrder;
