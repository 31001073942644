import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "../../scss/home.scss";
import { useAuth } from "user-context-fran-dev";
import GenericUserMenu from "../../components/GenericUserMenu";

export default function InicioDash({ changeMode, mode }) {
  const { auth } = useAuth();
  console.log(auth);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none" /* border: "2px solid cyan" */,
        }} /* className='home-nav' */
      >
        <Toolbar sx={{ mb: 0, pb: 0 }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "text.primary" }}
          >
            <strong>Keskinube</strong>
          </Typography>
          <div>
            <Typography sx={{ display: "inline", color: "text.primary" }}>
              <strong>{auth.user.first_name}</strong>
            </Typography>
            <GenericUserMenu
              changeMode={changeMode}
              mode={mode}
              color={{ light: "#000", dark: "#fff" }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
