
import { BusinessSettings } from "../businessManages";
import { DomainList } from "../landing/domainList";

export const CreateMenu = (logout) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let newData = [];
    /* let Fmenu = getData(id);
    console.log(text)
    Fmenu= Fmenu.menu.find((x)=> x.text === text)
    console.log(Fmenu) */

    const UpdateStyles = () => {
        (async () => {
            let useBusines = new BusinessSettings();
            let response = await useBusines.getAllByIdAuth("1", logout);
            GetListPages(response.data.domain)
        })();
    };

    const GetListPages = async (domain) => {
        let irequestFilter = [];
        irequestFilter.push({ key: "domain", value: `${domain}` }); // TODO:  test.74-208-117-143.plesk.page ${domain}
        let getArticle = new DomainList();
        let response = await getArticle.filter(irequestFilter);

        for (const key in response.data) {
            if (response.data[key].is_active) {
                console.log(response.data[key].link)
                newData.push({ text: response.data[key].name, url: response.data[key].link.replace("/", "") }); //{text: element.name, url: element.replace("/", "")}
            }
        }
        console.log(newData)
    }

    UpdateStyles();
    return newData;
}

export const ChatHistory = (logout) => {
    
    let newData = [];

    const UpdateStyles = () => {
        (async () => {
            let useBusines = new BusinessSettings();
            let response = await useBusines.getAllByIdAuth("1", logout);
            GetListPages(response.data.domain)
        })();
    };

    const GetListPages = async (domain) => {
        let irequestFilter = [];
        irequestFilter.push({ key: "domain", value: `${domain}` }); // TODO:  test.74-208-117-143.plesk.page ${domain}
        let getArticle = new DomainList();
        let response = await getArticle.filter(irequestFilter);

        for (const key in response.data) {
            if (response.data[key].is_active) {
                console.log(response.data[key].link)
                newData.push({ text: response.data[key].name, url: response.data[key].link.replace("/", "") }); //{text: element.name, url: element.replace("/", "")}
            }
        }
        console.log(newData)
    }

    UpdateStyles();
    return newData;
}