import * as React from "react";
import {
  Box,
  IconButton,
  Link,
  Grid
} from "@mui/material";

import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/joe_keski_alt.jpeg'

// Dynamic links in navbar and categories submenu
export default function LinksNavbar(props) { //funcoin para actulizar el json
  const {
    navItems,
    text,
    icon,
    getEndpoint
  } = props
  const [categories, setCategories] = React.useState(null)
  const navigate = useNavigate();

  const handleNavigation = (link) => {
    navigate(link); // Redirige dinámicamente a la ruta proporcionada
  };


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {icon ?
        <Box
          component="img"
          src={logo}
          alt={`Logo cuadrado para sección de categorias de`}
          onClick={() => handleNavigation('/landing/root')}

          sx={{
            height: "3vh",
            width: "auto",
            cursor: 'pointer',

            // width: { xs: '100%', md: '30%' },
            // aspectRatio: '1/1',
            maxWidth: "50%",
          }}
        /> :
        <Link

          onClick={() => handleNavigation('/')}
          style={{
            margin: "16px 16px 16px 0px",//margenes appbar
            display: "block",
            textDecoration: "none",

          }}
        >
          Estas en Inicio
        </Link>
      }

      <Box
        sx={{
          display: { md: "flex" },
          textAlign: "center",
        }}
      >

        {navItems &&
          navItems.map((page) => {
            {
              /* Show the links with display=true */
            }
            if (page.display === true) {

              if (page.tag === "TAG_LOCATION") {
                return (

                  <Box
                    key={page.tag}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >

                    <IconButton
                      id="icon_location"
                      href="#"
                      onClick={() => {

                        navigate('/landing/location');
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: 'black',
                        width: 40,
                        height: 40,

                      }}

                    >
                      <RoomOutlinedIcon
                        sx={{ width: 28, height: 28 }} />
                    </IconButton>

                  </Box>
                );
              } else {
                return (
                  <Link
                    key={page.title}
                    onClick={() => handleNavigation(page.link)} // Usa handleNavigation aquí
                    // onClick={() => {
                    // funcion para actulizar el json como parametro rl page.link
                    //   navigate(page.link); //no es un navigate vamos pasando una funcion del creator al appbar para actualizar el json correspondiente
                    // }}
                    style={{
                      margin: "16px 16px 16px 0px",//margenes appbar
                      color: text ? 'black' : '',
                      display: "block",
                      textDecoration: "none",
                      cursor: "pointer"
                    }}
                  >
                    {page.title}
                  </Link>
                );
              }

            }
          })}
      </Box>

    </Grid>
  );
}

function initialValues() {
  return {
    pattern: "",
  };
}
