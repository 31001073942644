import React, { useState, useEffect } from "react";
import BasicModal from "../../../../components/GenericModal";
import { TextField, Grid, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "user-context-fran-dev";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import { initScrap, ValScrap } from "../../../../utils/formHelper";
import ScrapService from "../../../../api/inventory/scrapService";
import WarehouseService from "../../../../api/warehouseService";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";

const AddScrap = (props) => {
  const { openModal, setOpenModal, UpdateScrap } = props;
  const [loading, setLoading] = useState(false);
  const { logout, auth } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();

  const [warehouseList, setWarehouseList] = useState("");

  useEffect(() => {
    warehouseListRequest();
  }, []);

  const warehouseListRequest = () => {
    (async () => {
      const warehouseService = new WarehouseService();
      const response = await warehouseService.getAllAuth(logout);
      setWarehouseList(response.data);
      console.log("warehouseList", response.data);
    })();
  };

  const formik = useFormik({
    initialValues: initScrap(auth.user.pk),
    validationSchema: Yup.object(ValScrap()),
    onSubmit: async (formData) => {
      setLoading(true);
      let scrapService = new ScrapService();
      let response = await scrapService.postDataAuth(formData, logout);
      sendNotification(UseerrorOrsuccess(response));
      setLoading(false);
      if (Number(response?.status) < 300) {
        window.localStorage.setItem("menu", 16);
        navigate(`scrap/${response?.data.id}`);
        setOpenModal(false);
      } else {
      }
      UpdateScrap();
      setLoading(false);
    },
  });

  return (
    <>
      <BasicModal
        open={openModal}
        setOpen={setOpenModal}
        title="Crear orden de desecho"
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="justification"
              label="Justificacion"
              margin="dense"
              size="small"
              value={formik.values.justification}
              onChange={formik.handleChange}
              error={
                formik.touched.justification &&
                Boolean(formik.errors.justification)
              }
              helperText={
                formik.touched.justification ? formik.errors.justification : ""
              }
            />
          </Grid>
          <Grid container sx={{ marginTop: 3 }}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel error={formik.touched.warehouse} id="warehouse">
                Almacen
              </InputLabel>
              <Select
                labelId="warehouse"
                id="warehouse"
                name="warehouse"
                label="Almacen"
                fullWidth
                size="small"
                value={formik.values.warehouse}
                onChange={formik.handleChange}
                error={formik.touched.warehouse}
                helperText={
                  formik.touched.warehouse ? formik.errors.warehouse : ""
                }
              >
                {/*           <MenuItem value="">
                            <em>Almacen</em>
                          </MenuItem> */}
                {warehouseList
                  ? warehouseList.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
              <FormHelperText
                sx={{
                  display: formik.touched.warehouse ? "" : "none",
                  color: "error.main",
                }}
              >
                {formik.touched.warehouse ? formik.errors.warehouse : ""}
              </FormHelperText>
            </FormControl>
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={12}
                sm={7}
                md={6}
                lg={6}
                xl={6}
                sx={{ marginTop: "15px" }}
              >
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  Agregar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </BasicModal>
    </>
  );
};

export default AddScrap;
