import React, { useState } from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const GenericPopoverResponsive = ({ title, children }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Button
                fullWidth
                variant="text"
                onClick={handleToggle}
                sx={{
                    color: 'black',
                    justifyContent: 'space-between',
                    textTransform: 'none',
                    padding: 1,

                }}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                {title}
            </Button>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 2,
                    }}
                >
                    {children}
                </Box>
            </Collapse>
        </Box>
    );
};

export default GenericPopoverResponsive;