import React, { useState } from "react";
import {
  Typography,
  Grid,
  styled,
  Paper,
  Stack,
  TextField,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import {
  InventoryService,
  InventoryServiceSalesOrder,
} from "../../api/inventoryService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import {
  initAddArticleToPurchaseOrder,
  valSchAddToPurchaseOrder,
} from "../../utils/formHelper";
import { GenericDrawer } from "../../components/keskiCreator/genericDrawer";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageSize from "../ImageSize";
import { LoadingButton } from "@mui/lab";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { formatNumber } from "../../utils/functions";

const Item = styled(Paper)(({ theme }) => ({
  margin: "16px 0",
}));

export default function DrawerArticle(props) {
  const { id } = useParams();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [msg, sendNotification] = useNotification();

  const labels = ["Ord:", "Dis:", "Res:"];
  const keys = ["order", "availabl", "reserved"];
  const order_data = labels.map((label, index) => ({
    label,
    key: keys[index],
  }));

  const FormData = [
    {
      filter: "cost",
      label: "Costo",
    },
    {
      filter: "quantity",
      label: "Cantidad",
    },
  ];
  const Form = ({ data }) => {
    const formik = useFormik({
      initialValues: initAddArticleToPurchaseOrder(data),
      validationSchema: false,
      onSubmit: async (formData, { resetForm }) => {
        formik.values.presentation = data?.id;
        formik.values.model = props.dataProduct.model.id;
        formik.values.cost = data.cost;
        resetForm();
        setIsSubmitting(true); // Hasta que pase los datos del form, desabhilitara el boton para evitar volver a poder agregar hasta que sea un success o error
        setLoading(true);
        console.log(formData);
        if (props.type == "purchase") {
          console.log("purchase", data);
          /*  formik.values.cost = row.price */
          formik.values.purchase = Number(id);
          let addToPurchases = new InventoryService();
          let response = await addToPurchases.postDataAuth(formData, logout);
          if (Number(response.status) < 300) {
            sendNotification(UseerrorOrsuccess(response));
          } else {
            sendNotification(UseerrorOrsuccess(response));
          }
          props.reloadDataOforder();
        } else {
          formik.values.sale = Number(id);
          /* formik.values.price = row.price */
          let addToPurchases = new InventoryServiceSalesOrder();
          let response = await addToPurchases.postDataAuth(formData, logout);
          props.reloadDataOforder();
        }
        //udateProducts();
        //setOpen(false);
        setLoading(false);
        setIsSubmitting(false); // Una ves ya pasado todo el formulario y si fue success o error, volvera activar el boton de agregar
      },
    });

    return (
      <Stack
        component="form"
        direction={"row"}
        onSubmit={formik.handleSubmit}
        justifyContent={"space-between"}
        p={"8px"}
      >
        {FormData.map((field) => (
          <TextField
            key={field.label}
            label={field.label}
            variant="outlined"
            size="small"
            type={field.filter === "cost" ? "text" : "number"}
            name={field.filter}
            value={formik.values[field.filter] || ""}
            onChange={formik.handleChange}
            error={
              formik.touched[field.filter] &&
              Boolean(formik.errors[field.filter])
            }
            helperText={
              formik.touched[field.filter] && formik.errors[field.filter]
            }
            sx={{ width: "30%" }}
          />
        ))}

        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          sx={{ borderRadius: "60px" }}
          disabled={isSubmitting || !data.is_active}
        >
          Agregar
        </LoadingButton>
      </Stack>
    );
  };

  return (
    <GenericDrawer
      open={props.openDrawer}
      toggleDrawer={props.toggleDrawer}
      anchor={"right1"}
      drawerWidth="30%"
    >
      <Box p={"16px"}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"20px"} p={"8px 0"}>
            <b>Presentaciones</b>
          </Typography>

          <IconButton onClick={props.close}>
            <CloseSharpIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid display={"flex"} p={"16px 0"}>
          <ImageSize
            img={props.dataProduct?.model?.image?.url || ""}
            weight={2}
          />
          <div>
            <Typography>
              <b>{props.dataProduct?.product?.name}</b>
            </Typography>
            <Typography color="gray">
              {props.dataProduct?.model?.name}
            </Typography>
          </div>
        </Grid>
        {(props.dataProduct?.presentations || []).map((presentation, index) => (
          <Item key={index} elevation={4}>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              p={"8px 8px 0 8px"}
            >
              <Typography>
                <strong>{presentation.name}</strong>
              </Typography>
              <Typography color={presentation.is_active ? "green" : "red"}>
                {presentation.is_active ? "Activo" : "Desactivado"}
              </Typography>
            </Stack>

            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              p={"8px"}
            >
              {order_data.map((data) => (
                <Typography key={data}>
                  {data.label} {presentation[data.key] || "0"}{" "}
                  {/* Add fallback for undefined values */}
                </Typography>
              ))}
            </Stack>

            <Divider />
            <Form data={presentation} />
          </Item>
        ))}
      </Box>
    </GenericDrawer>
  );
}
