
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import '../scss/modal.css'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from "@mui/material"
import Grow from '@mui/material/Grow';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { motion } from "framer-motion"




/* const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  borderRadius : '7px',
/*   border: '2px solid #000', 
  boxShadow: 24,
  p: 4,
};
 */



export function TableModal(props) {
  const { open, setOpen, children } = props

  return (
    <div>

      <Modal
        open={open || false}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center"
          alignItems="center"  >
          <Grid
            className='box'
            item xs={12} sm={11} md={11} lg={9} xl={7}
            sx={{ bgcolor: 'background.paper' }}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs={11} >

                {props.title && <Typography variant="h6" gutterBottom component="div" sx={{ p: 0, m: 0 }}>
                  <strong> {props.title}</strong>
                </Typography>}
              </Grid>
              <Grid item xs={1}   >
                <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    sx={{ p: 0, m: 0 }}
                    className="icon"
                    onClick={() => setOpen(false)}
                    aria-label="delete">
                    <CloseIcon sx={{ color: 'text.primary', p: 0, m: 0 }} />
                  </IconButton>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} mt={"16px"}>
              {children}
            </Grid>
          </Grid>
        </Grid>

      </Modal>
    </div>
  );
}


/* ------- BottonModal ------ */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: "flex-end",
    /* justifyContent: "flex-end" */
  },
  '& .MuiPaper-root': {
    margin: 0,
    /* height: "100vh", */
    /*   width:  "100%" "calc(100% - 16px)" */
    width: "800px"
  },
}));


export const BottonModalDiag = (props) => {
  const { open, setOpen, title, children, size } = props
  const theme = useTheme();
  /*   const fullScreen = useMediaQuery(theme.breakpoints.down('xs')); */

  /* useEffect(()=>{
    const width = document.getElementById("main-class-container");
    let compStyles = window.getComputedStyle(width);
    console.log(window.innerWidth)
    width.style.background = "red"
    console.log( compStyles.getPropertyValue('width'))
  },[window.innerWidth]) */

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BootstrapDialog
      /* fullWidth={true} */
      maxWidth={"lg"}
      fullWidth={true}
      width={size || "lg"}
      open={open}

      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ textAlign: title.align }}>
        <Grid container direction="row"
          justifyContent="start"
          alignItems="start" >
          <Grid xs={8}>
            {title.text}
          </Grid>
          <Grid xs textAlign={'end'}>
            <IconButton aria-label="CloseIcon" color="secondary" onClick={() => { handleClose() }}>
              <CloseIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "80vh", overflowY: "auto" }} className="invisible-scrollbar">
        {/*           <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
        {/*           <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          > */}
        {children}
        {/*             {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')} */}


        {/*  </Box> */}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={()=>{console.log("hola")}}>Close</Button> */}
      </DialogActions>
    </BootstrapDialog>
  );
}



/*------------ Dialog Modal con modificaciones ----------------------- */

const TransitionMod = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MobDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    margin: 0,
    /* height: "100vh", */
    width: "100%"/* "calc(100% - 16px)" */

  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

/* BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}; */

export default function CustomizedDialogs({ open, setOpen, children, title, size, actions }) {
  /*  const [open, setOpen] = React.useState(false); */

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (


    <MobDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={size || "sm"}
      scroll={"paper"}
      TransitionComponent={TransitionMod}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent /* dividers */ sx={{ maxHeight: "80vh", overflowY: "auto" }}>
        {children}
        {/*           
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')} 
              */}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </MobDialog>

  );
}


/* ------ ModalRight ------ */

const TransitionRight = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={800} />;
});

const MobDialogRight = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    margin: 0,
    height: "100%",
    width: "100%",/* "calc(100% - 16px)" */
    maxHeight: "100%",

  },
  '& .MuiDialog-container': {
    margin: 0,
    justifyContent: "end",
    alignItems: "start",

  },
}));

export function ModalRight({ open, setOpen, children, title, size, byBreakpoints }) {
  /*  const [open, setOpen] = React.useState(false); */

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /*    const sixe ={xs: "100%", sm: "60%", md: "50%", lg: "20%"}; */

  return (


    <MobDialogRight
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={!byBreakpoints ? size || "xs" : ""}
      sx={{
        fontFamily: 'bold',
        '& .MuiPaper-root': {
          maxWidth: byBreakpoints
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
      }}
      scroll={"paper"}
      TransitionComponent={TransitionRight}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} sx={{ fontWeight: 'bold' }}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent /* dividers */ sx={{ maxHeight: "90vh", overflowY: "auto" }}>
        {children}
        {/*            {[...new Array(50)]
               .map(
                 () => `Cras mattis consectetur purus sit amet fermentum.
 Cras justo odio, dapibus ac facilisis in, egestas eget quam.
 Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
 Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
               )
               .join('\n')} */}
      </DialogContent>
      {/*         <DialogActions>
           <Button autoFocus onClick={handleClose}>
             Save changes
           </Button>
         </DialogActions> */}
    </MobDialogRight>
  );
}






