import { Drawer } from "@mui/material";

export function GenericDrawer(props) {
  const { open, toggleDrawer, anchor, drawerWidth, children } = props;

  function finalAnchor(anchor) {
    if (anchor === "right1" || anchor === "right2") {
      return "right";
    } else if (anchor === "top" || anchor === "top1") {
      return "top";
    } else {
      return anchor;
    }
  }

  function finalWidth(anchor, drawerWidth) {
    if (anchor === "top" || anchor === "top1") {
      return "100%";
    } else {
      if (drawerWidth) {
        return { xs: "100%", sm: "50%", md: drawerWidth };
      } else {
        return "auto";
      }
    }
  }

  return (
    <Drawer
      variant="temporary"
      transitionDuration={500}
      anchor={finalAnchor(anchor)}
      open={open[anchor]}
      onClose={() => toggleDrawer(anchor, false)}
      sx={{
        height: "calc(100%-200px)",
        "& .MuiDrawer-root": {
          zIndex: "999",
        },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: finalWidth(anchor, drawerWidth),
          maxHeight: "100vh",
          overflowX: "auto",
          paddingTop: { xs: "55px", md: "64px" },
        },
        "& .MuiBackdrop-root": {
          marginTop: "64px",
        },
      }}
    >
      {children}
    </Drawer>
  );
}

/*
USE IN THE COMPONENT:

const [openDrawer, setOpenDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer({ ...openDrawer, [anchor]: open });
  };

  <GenericDrawer open={openDrawer} toggleDrawer={toggleDrawer} anchor='top' drawerWidth="35%">
    {drawer}
  </GenericDrawer>
*/
