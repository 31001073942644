import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import BottonModal from "../../../components/BottonModal";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddCategory from "./addCategory";
import CategoryManages from "../../../api/categoryManages";
import AddIcon from "@mui/icons-material/Add";
import "../../../scss/catalog.scss";
import { useAuth } from "user-context-fran-dev";

const ManageCategory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openAddCat, setOpenAddCat] = useState(false);
  const [table, setTable] = useState(null);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState("");
  const { logout } = useAuth();

  const UdateTable = () => {
    (async () => {
      let getCategorys = new CategoryManages();
      let response = await getCategorys.getAllAuth(logout);
      setTable(response.data);
    })();
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          UdateTable();
          setOpenModal(true);
        }}
        sx={{ height: "30px", borderRadius: "60px", m: "0 8px" }}
      >
        categorías
      </Button>

      <BottonModal open={openModal} setOpen={setOpenModal}>
        <Grid container sx={{ mt: 1 }}>
          <Grid item xs={7} sm={6} pl={2} mb={1}>
            <Typography variant="h6" gutterBottom component="div">
              <strong>Gestionar Categorías </strong>
            </Typography>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Grid container sx={{ justifyContent: "flex-end" }}>
              <Button
                variant="text"
                mb={1}
                onClick={() => {
                  setOpenAddCat(true);
                  setEdit(false);
                  setData("");
                }}
                startIcon={<AddIcon fontSize="small" />}
              >
                Agregar categoría
              </Button>
              {/*          <Link 
                  component="button"
                  variant="body1"
                  underline="none"
                  onClick={()=>{ setOpenAddCat(true); setEdit(false)} }>
                      <AddIcon fontSize="small" />  Agregar categoría
                  </Link> */}

              <AddCategory
                update={UdateTable}
                open={openAddCat}
                setOpen={setOpenAddCat}
                edit={edit}
                data={data}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {table && (
              <List className="list">
                {table.map((data, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemText primary={`${index + 1}. ${data.name}`} />

                      <Link
                        edge="end"
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() => {
                          setOpenAddCat(true);
                          setEdit(true);
                          setData(data);
                        }}
                      >
                        Editar
                      </Link>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </BottonModal>
    </div>
  );
};

export default ManageCategory;
