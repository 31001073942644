import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getData } from "../api/data/inicioData";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useAuth } from "user-context-fran-dev";
import GenericUserMenu from "../components/GenericUserMenu";
import { APPNAME } from "../utils/constants";
/* import Footer from "../components/Footer"; */
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Button, Grid, useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { replace } from "formik";
import MetaData from "../components/landing/metaData";
import LandingMetaData from "../../src/pages/landing/landingMetaData";
import BasicPopover from "../../src/components/GenericPopover";
import { color } from "framer-motion";
import keskinube_blanco from "../../src/assets/keskinube_blanco.png";
import { BusinessSettings } from "../api/businessManages";
import { useLocation } from "react-router-dom";
import GenericPopoverResponsive from "../components/GenericPopoverResponsive";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    /* width: `calc(100% - ${drawerWidth}px)`, */
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const renderMenu = (menus) => (
  <>
    {menus.menu.map((data, index) => (
      <div key={index}>
        {!data?.notfinish && (
          <div key={data.text}>
            {!data.nestedMenu ? (
              <IconButton
                key={data.text}
                onClick={() => {
                  console.log("ESTE ES EL DATA URL" + data.url);
                  const navigate = useNavigate();
                  navigate(`/${data.url}`);
                }}
                color="inherit"
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.text} />
              </IconButton>
            ) : (
              <NestedList data={data} menus={menus} />
            )}
            {data.gohome && <Divider />}
          </div>
        )}
      </div>
    ))}
  </>
);

export const renderMenuList = (menus, id, navigate) => (
  // console.log(menus)

  <>
    {menus.menu.map((data, index) => (
      <div key={index}>
        {!data?.notfinish && (
          <div key={data.text}>
            {!data.nestedMenu ? (
              <ListItem
                button
                key={data.text}
                onClick={() => {
                  navigate(`/${data.url}`);

                  /*                   console.log(id)
                                  debugger */
                }}
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItem>
            ) : (
              <NestedList data={data} menus={menus} />
            )}
            {data.gohome && <Divider />}
          </div>
        )}
      </div>
    ))}
  </>
);

export default function MenuDrawer(props) {
  const [domain, setDomain] = useState("");

  // Función para obtener el dominio
  const getDomain = async () => {
    let useBusiness = new BusinessSettings();
    let response = await useBusiness.getAllByIdAuth("1", logout);

    // Suponiendo que la respuesta tiene la propiedad domain
    if (response.data && response.data.domain) {
      setDomain(response.data.domain);
    }
  };

  useEffect(() => {
    getDomain();
  }, []);

  const handleNavigateMyPage = () => {
    // Asegurarte de que la URL tenga el protocolo (http o https)
    const completeDomain = domain.startsWith("http")
      ? domain
      : `https://${domain}`;

    // Redirige a la URL completa en la misma pestaña
    window.open(completeDomain, "_self");
  };
  const navigate = useNavigate();
  const { id /* page */ } = useParams();
  const [menus, setMenus] = React.useState(
    getData(parseInt(window.localStorage.getItem("menu")))
  );
  if (window.localStorage.getItem("menu")) {
    if (menus.id !== parseInt(window.localStorage.getItem("menu"))) {
      console.log(
        "menu",
        getData(parseInt(window.localStorage.getItem("menu")))
      );
      setMenus(getData(parseInt(window.localStorage.getItem("menu"))));
      /* console.log(menus) */
    }
  }

  /*  var menus =  getData(parseInt(window.localStorage.getItem('menu'))) */

  const [open, setOpen] = React.useState(true);
  const { logout, auth, online } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  /* const [anchorE2, setAnchorE2] = React.useState(null); */
  const isCellPhone = window.innerWidth < 769 ? true : false;
  const isMobile = useMediaQuery("(max-width:600px)");
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (props.posDrawer == "false") {
      setOpen(false);
    }
    function upDateMenu() {
      setMenus(getData(parseInt(window.localStorage.getItem("menu"))));
      /* console.log(menus) */
      /* menus =  getData(parseInt(window.localStorage.getItem('menu'))) */
      /* console.log(menus) */
    }
    if (menus) {
      if (menus.id !== parseInt(window.localStorage.getItem("menu"))) {
        upDateMenu();
      }
    } else {
      /* console.log("su maere 222") */
      navigate("/home");
    }
  }, [props.reload, id]);
  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleClosePhone = () => {
    if (isCellPhone && open) {
      setOpen(!open);
    }
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");

  // Define los estilos en función de la pantalla
  const logoStyles = {
    width: isSmallScreen ? "170px" : "200px",
    marginLeft: isSmallScreen ? "0px" : isMediumScreen ? "0px" : "50px",
  };

  const location = useLocation();

  // Verifica si la ruta actual incluye "landing"
  const isLandingRoute = location.pathname.includes("landing");

  const [openCreator, setOpenCreator] = useState(false);
  const handleDrawerCreator = () => {
    setOpenCreator(!openCreator);
  };
  return (
    <>
      {menus && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
          >
            <Toolbar>
              {" "}
              {/* ESTE ES EL NAV BAR MORADO QUE TIENE TODO EN SI */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                sx={{ display: menus.id === 2 ? "none" : "" }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{ marginLeft: "10px" }}
              >
                <strong>{`${menus.title ? menus.title : ""}`}</strong>{" "}
                {/* ESTE ES EL TITULO DEL NAV BAR */}
              </Typography>
              <div style={{ position: "fixed", right: "4%" }}>
                <Grid container>
                  <Grid>
                    {/* {online &&
                      <Tooltip title="Conexión a notificaciones en tiempo real">
                        <LinkIcon style={{ textAlign: 'center', color: 'green' }} sx={{ marginTop: '13px' }} />
                      </Tooltip>
                    }
                    {!online &&
                      <Tooltip title="Conexión a notificaciones en tiempo real">
                        <LinkOffIcon style={{ textAlign: 'center', color: 'red' }} sx={{ marginTop: '13px' }} />
                      </Tooltip>
                    } */}
                    {menus.MetaData && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <BasicPopover
                            title={"Personalizar"}
                            children={
                              <LandingMetaData menu={2} view={"Estilo"} />
                            }
                          ></BasicPopover>
                          <BasicPopover
                            title={"Informacion"}
                            children={
                              <LandingMetaData menu={2} view={"Metadata"} />
                            }
                          ></BasicPopover>
                          <Button variant="text" sx={{ color: "white" }}>
                            Ir a mi pagina
                          </Button>
                        </Box>
                      </>
                    )}
                  </Grid>

                  <Grid>
                    <GenericUserMenu
                      changeMode={props.changeMode}
                      mode={props.mode}
                    />{" "}
                    {/*ESTE ES EL ICONO DONDE PODEMOS CERRAR SESION */}
                  </Grid>
                  {isLandingRoute && (
                    <IconButton
                      color="inherit"
                      onClick={handleDrawerCreator}
                      sx={{
                        color: "white",
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                      // onClick={handleDrawer}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  <Drawer
                    anchor="top"
                    open={openCreator}
                    onClose={handleDrawerCreator}
                    sx={{
                      "& .MuiDrawer-paper": {
                        width: "100%",
                        height: "50vh",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        mt: "50px",
                        ml: "10px",
                      }}
                    >
                      {menus.MetaData && (
                        <Box
                          sx={{
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {/*Crear O Agregar un Tipo PopOver para este*/}
                          <GenericPopoverResponsive title="Personalizar">
                            <LandingMetaData menu={2} view={"Estilo"} />
                            <LandingMetaData menu={2} view={"Libreria"} />
                          </GenericPopoverResponsive>
                          {/*Crear O Agregar un Tipo PopOver para este*/}
                          <GenericPopoverResponsive title="Información">
                            <LandingMetaData menu={2} view={"Metadata"} />
                            <LandingMetaData menu={2} view={"Legal"} />
                          </GenericPopoverResponsive>
                        </Box>
                      )}
                      <Button
                        variant="text"
                        sx={{
                          ml: "17px",
                          color: "black",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "rgba(186, 85, 211, 0.1)",
                            color: "black",
                          },
                        }}
                        onClick={handleNavigateMyPage}
                      >
                        Ir a mi pagína
                      </Button>
                    </Box>
                  </Drawer>
                </Grid>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer //TODO Y LO DE ADENTRO DE LIST, ES EL DRAWER LIST QUE ENSEÑA EL MENU DE METADATA, KESKICREATOR, ETC ETC
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              display: menus.id === 2 ? "none" : "",
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader></DrawerHeader>
            <Divider />

            <List>
              {menus.menu.map((data, index) => (
                <div key={index}>
                  {!data?.notfinish && (
                    <div key={data.text}>
                      {!data.nestedMenu ? (
                        <ListItem
                          button
                          key={data.text}
                          onClick={() => {
                            if (data.comeback) {
                              window.localStorage.setItem(
                                "menu",
                                data.comeback
                              );
                              navigate(data.url);
                              return;
                            }

                            if (data.url && id) {
                              navigate(`${data.url}/${id}`);
                              return;
                            }
                            if (data.url) {
                              navigate(data.url);
                              return;
                            }
                            /*                   console.log(id)
                                            debugger */
                          }}
                        >
                          <ListItemIcon>{data.icon}</ListItemIcon>
                          <ListItemText primary={data.text} />
                        </ListItem>
                      ) : (
                        <NestedList data={data} menus={menus} />
                      )}
                      {data.gohome && <Divider />}
                    </div>
                  )}
                </div>
              ))}
            </List>
          </Drawer>
          <Main
            /* id="main-class-container" */ onClick={handleClosePhone}
            open={isCellPhone ? false : open}
            sx={{
              padding: 0,
            }} /* style={{paddingLeft: '16px', paddingRight: '16px'}} */
          >
            {/*             <div style={{height: '94vh' }} onClick={handleClosePhone}>

        </div> */}
            <DrawerHeader />
            <Outlet />

            {/*            <Footer /> */}
          </Main>
        </Box>
      )}
    </>
  );
}

const NestedList = ({ data, menus }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [dnMenu, setDnMenu] = useState("");
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    console.log();
    if (data.hasOwnProperty("dynamicMenu")) {
      setDnMenu(data.dynamicMenu(logout));
      setOpen(false);
    }
  }, []);

  if (data.hasOwnProperty("dynamicMenu")) {
    return (
      <>
        {dnMenu && (
          <>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>{data.icon} </ListItemIcon>
              <ListItemText primary={data.text} />
              {open ? <ExpandLess /> : <ExpandMore />} {/* open */}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {" "}
              {/* open */}
              <List component="div" disablePadding>
                {dnMenu &&
                  dnMenu.map((data, index) => (
                    <ListItem
                      key={index}
                      button
                      sx={{ pl: 4 }}
                      onClick={() => {
                        navigate(`/landing/${data.url}`);
                      }}
                    >
                      <ListItemIcon>{data.icon}</ListItemIcon>
                      <ListItemText primary={data.text} />
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>{data.icon} </ListItemIcon>
          <ListItemText primary={data.text} />
          {open ? <ExpandLess /> : <ExpandMore />} {/* open */}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {" "}
          {/* open */}
          <List component="div" disablePadding>
            {data.nestedMenu.map((data, index) => (
              <ListItem
                key={index}
                button
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate(data.url);
                }}
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }
};
