import * as context from "user-context-fran-dev";

export const BASE_PATH = context.getBaseUrl();
export const BASE_PATH_DASH = "https://api.suajam.com/api/v1";

export const STATUS = ["", "Borrador", "Ordenado", "Completado", "Cancelado"];
export const STATUSCOLOR = ["", "default", "primary", "success", "error"];
export const STATUSSALES = [
  "",
  "Borrador",
  "Reservado",
  "Completado",
  "Cancelado",
];
export const STATUSSALESCOLOR = ["", "default", "primary", "success", "error"];
export const STATUSPRODUCTION = [
  "",
  "Borrador",
  "Pre-producción",
  "En Progreso",
  "Completado",
  "Cancelado",
];
export const STATUSPRODUCTIONCOLOR = [
  "",
  "default",
  "primary",
  "warning",
  "success",
  "error",
];
export const STATUSCASHREGISTER = ["", "Abierto", "Cerrado", "Cancelado"];
export const STATUSPOSORDER = ["", "Borrador", "Pagado", "Cancelado"];
export const STATUSADJUSTMENT = ["", "Borrador", "Ajustado", "Cancelado"];
export const STATUSADJUSTMENTCOLOR = ["", "default", "primary", "warning"];
export const STATUSSCRAP = [
  "",
  "Borrador",
  "Completado",
  "Desechado",
  "Cancelado",
];
export const STATUSSCRAPCOLOR = [
  "",
  "default",
  "primary",
  "warning",
  "warning",
];
export const STATUSTRANSFER = [
  "",
  "Borrador",
  "Reservado",
  "Entregado",
  "Completado",
  "Cancelado",
];
export const STATUSTRANSFERCOLOR = [
  "",
  "default",
  "primary",
  "success",
  "success",
  "warning",
];
export const STATUSSTOREORDER = [
  "",
  "Borrador",
  "Procesando",
  "Pagado",
  "Confirmado",
  "Enviado",
  "Entregado",
  "Cancelado",
  "Fuera de tiempo",
  "Fallido",
];
export const STATUSSTOREORDERCOLOR = [
  "",
  "default",
  "warning",
  "primary",
  "success",
  "primary",
  "success",
  "error",
  "warning",
  "error",
];
export const APPNAME = "keskinube"; //APP_NAME

//Administracion, Catalogo de articulos, Creador de sitio web, Gestor inventarios, Gestor punbto de venta, Gestor tienda en linea
