import React, {useState} from "react";
import { Grid, Typography, TextField, Link, Paper,CardHeader, Divider  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { STATUS, STATUSCOLOR } from '../../../utils/constants'
import ArticlesListPreview from '../../../components/inventario/tables/ArticlesListPreview'
import Chip from '@mui/material/Chip';

const TableInfo = (props) => {

  const { data } = props
  const navigate = useNavigate();
  console.log('tableInfo', data)

    

    return ( 
        <Grid container>
            {/* xs={12} sm={12} md={11} lg={11} xl={11} */}
            <Grid item xs={6} mb={1}>  
                <Typography variant="subtitle1" gutterBottom component="div">
                <Chip label={STATUS[data.status]} color={STATUSCOLOR[data.status]}  variant="outlined"/>
                </Typography>
            </Grid>
            <Grid item xs={6} mb={1}>  
                <Grid container justifyContent="flex-end">
                    <Link  onClick={() => { window.localStorage.setItem("menu", 11); navigate(`detail/${data.id}`)}} 
                        underline="none">
                        <Typography variant="subtitle1"  gutterBottom component="div">
                            Explorar 
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} >  
              <TextField
                fullWidth
               
                label="Condiciones de Pago"
                defaultValue={data.pay_conditions ? data.pay_conditions : ''}
                InputProps={{
                    readOnly: true,
                }}
                sx={{margin: '6px'}}
                />
              <TextField
              fullWidth
                id="outlined-read-only-input"
                label="Dias de credito"
                defaultValue={data.credit_days ? data.credit_days : ''}
                InputProps={{
                    readOnly: true,
                }}
                sx={{margin: '6px'}}
              />
            </Grid>

            <Grid item xs={12} sm={4} >  
            <TextField
              fullWidth
              className="space-text-field"
              label="Metodo de pago"
              defaultValue={data.pay_method}
              InputProps={{
                  readOnly: true,
              }}
              sx={{margin: '6px'}}
              />
            <TextField
              fullWidth
              id="outlined-read-only-input"
              label="IVA"
              defaultValue={data.iva ? data.iva : ''}
              InputProps={{
                  readOnly: true,
              }}
              sx={{margin: '6px'}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>  
            <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Comentarios"
                multiline
                rows={4}
                defaultValue={data.comments ? data.comments : ""}
                InputProps={{
                    readOnly: true,
                }}
                sx={{margin: '6px'}}
                /> 
            </Grid>
            <Grid item xs={12}>
              <ArticlesListPreview data={data}></ArticlesListPreview>
            </Grid>
            
            </Grid>
     );
}

const TableArticleInfo = ({ row }) => {
  const [open, setOpen] = useState(false);
  return (<>
    <br/> 
    <Paper >
      <Grid container>
        <Grid item xs={12}>
          <Paper  elevation={2} sx={{backgroundColor: 'background.default', padding: '10px' }}>
          <Grid container>
            <Grid item xs={3}>
              <img
                src={row.model.image? row.model.image.url: ""}
                style={!open?{ width: "20%", height: "auto", marginBottom: "-6px", padding: "0px", transition: "1s" }
              :{ width: "50%", height: "auto", marginBottom: "-6px", padding: "0px", transition: "1s"  }}
              ></img>
            </Grid>
            <Grid item xs={3}>
              <CardHeader
              sx={{m: 0, p:0}}
                title={ 
                <Typography variant="subtitle2" component="div" sx={{ m:0, p:0 }}>
                  {row.presentation.name}
                  </Typography>}
                subheader={
                  open &&
                  <Typography variant="subtitle2" component="div" sx={{ m:0, p:0 }}>
                    {row.presentation.id}
                    </Typography>
                }
              />
            
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end" alignItems="center">
              <IconButton
                    sx={{m: 0, p: 0}}
                    onClick={() => {setOpen(!open) }}>
                    {open ? <KeyboardArrowUpIcon fontSize="small"  sx={{color: 'text.primary'}}/> :
                    <KeyboardArrowDownIcon fontSize="small" sx={{color: 'text.primary'}}/>}
                </IconButton>

              
                </Grid>
            </Grid>
            </Grid>
          </Paper>
        </Grid>


        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          
            <Grid container p={2} spacing={2}> 
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="outlined-read-only-input"
                  label="Modelo"
                  defaultValue={row.model.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ margin: "6px" }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="outlined-read-only-input"
                  label="Presentación"
                  defaultValue={row.presentation.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ margin: "6px" }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="outlined-read-only-input"
                  label="Restante"
                  defaultValue={row.quantity}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ margin: "6px" }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="outlined-read-only-input"
                  label="Pendiente"
                  defaultValue={row.quantity_pending}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ margin: "6px" }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

      </Grid>
    </Paper>
    </>
  );
};
 
export default TableInfo;