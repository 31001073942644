import { BASE_PATH } from "../../utils/constants";
import {BaseService} from 'base-service-fran-dev';


export class StoreSettings extends BaseService{
    constructor(){
      const baseUrl = BASE_PATH;
      const endPoint = 'store/settings';
      super(baseUrl, endPoint)
    }
}
