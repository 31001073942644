import { useEffect, useRef, useState } from "react";
import { ImageCreator } from "../../../api/landing/creatorManages";
import { useSnackbar } from "notistack";
import { LandingImages } from "../../../api/landing/homeLanding";
import { useAuth } from "user-context-fran-dev";

export const useImages = ({ setValue }) => {
  const [randomImages, setRandomImages] = useState([]);
  const [saveImages, setSaveImages] = useState([]);
  const imageCreator = new ImageCreator();
  const images = new LandingImages();
  const [isUploading, setIsUploading] = useState(false);
  const fileInput = useRef();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const maxSize = 32 * 1024 * 1024;

  const getImagesGenerated = async () => {
    try {
      const response = await images.getAll();
      if (response.status === 200) {
        setRandomImages(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getImagesGenerated();
    getImagesSaved();
  }, []);

  const getImagesSaved = async () => {
    const response = await imageCreator.getAllAuth(logout);
    setSaveImages(response.data);
  };

  const changeInput = async (e) => {
    const file = e.currentTarget.files;
    setIsUploading(true);
    try {
      if (file.length <= 1) {
        if (file[0].size > maxSize) {
          enqueueSnackbar(
            "La imagen es demasiado grande. El tamaño máximo permitido es de 32MB.",
            {
              autoHideDuration: 5000,
              variant: "warning",
            }
          );
        } else {
          var formData = new FormData();
          formData.append("imgUpload", file[0]);
          formData.append("type", 1);

          await imageCreator.uploadImg(formData, logout);
          getImagesSaved();
          setValue(0);
          setIsUploading(false);
        }
      } else {
        enqueueSnackbar("Maximo 1 imagen ", {
          autoHideDuration: 1000,
          variant: "warning",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteImage = async (id) => {
    try {
      await imageCreator.deleteAuth(id, logout);
      getImagesSaved();
    } catch (e) {
      console.error(e);
    }
  };

  const saveNewImage = async (url) => {
    try {
      const response = await imageCreator.postDataAuth(
        { url, type: 2 },
        logout
      );
      getImagesSaved();
      if (response.status === 201) {
        setValue(0);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const searchMoreImages = async (search) => {
    try {
      setRandomImages([]);
      const response = await images.postData({ query: search });
      if (response.status === 200) {
        setRandomImages(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    randomImages,
    saveImages,
    changeInput,
    isUploading,
    fileInput,
    deleteImage,
    saveNewImage,
    searchMoreImages,
  };
};
