import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import GenericTable from "../../GenericTable";
import TableCell from "@mui/material/TableCell";
import { TableRow } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useAuth } from "user-context-fran-dev";
import {
  InventoryService,
  InventoryServiceSalesOrder,
} from "../../../api/inventoryService";
import { ProductModelAvailableService } from "../../../api/productService";
import { useNotification } from "../../../hooks/snackBarResponse";
import {
  initOrderPurchaseANDSales,
  valSchOrderPurchaseANDSales,
} from "../../../utils/formHelper";
import GenericEmptyTable from "../../GenericEmptyTable";
import { TableModal } from "../../GenericsModals";
import ImageSize from "../../ImageSize";

export default function EditArticle(props) {
  const {
    openEdit,
    setOpenEdit,
    /* title, */
    dataProduct,
    type,
    reloadDataOforder,
    orderData,
  } = props;
  const [dataProductAva, setDataProduct] = useState("");
  /*   const [localDataProduct, setLocalDataProduct] = useState(""); */
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();
  console.log("orderDAta", orderData);
  console.log("dataProduct", dataProduct);

  const findArticle = () => {
    for (var article in orderData.articles) {
      console.log(orderData.articles[article]);
      if (orderData.articles[article].id === dataProduct) {
        return orderData.articles[article];
      }
    }
  };

  const getAvailabeProduct = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({
        key: "product",
        value: `${dataProduct.model.product.id}`,
      });
      irequestFilter.push({
        key: "mdl",
        value: `${dataProduct.model.id}`,
      });
      irequestFilter.push({ key: "wh", value: `${orderData.warehouse.id}` });
      let productInventory = new ProductModelAvailableService();
      let response = await productInventory.filter(irequestFilter, logout);
      setDataProduct(response?.data[0]);
      /* console.log(response.data[0].product_model[0].presentations.find((x)=> x.id == dataProduct.presentation.id) ) */
      /* find((x)=> x== dataProduct.presentation.id) */

      setLoading(false);
    })();
  };
  useEffect(() => {
    /*     setLoading(true);
        setLocalDataProduct(findArticle());
        console.log(findArticle());
        if (localDataProduct) {
          
        } */
    getAvailabeProduct();
  }, [orderData]);

  return (
    <>
      {
        /* dataProductAva && */ <TableModal
          open={openEdit}
          setOpen={setOpenEdit}
          title={"Editar presentación"}
          className="modal-articles"
        >
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item xs={9}>
              <Grid container>
                <ImageSize
                  img={
                    dataProduct?.model?.image
                      ? dataProduct?.model?.image.url
                      : ""
                  }
                  weight={2}
                />
                <Grid item ml={{ xs: "8px", md: "32px" }}>
                  <h3>{dataProduct?.model?.product?.name}</h3>
                  <h4>{dataProduct?.model?.name} </h4>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                justifyContent={"start"}
                alignItems={"end"}
                flexDirection="column"
                sx={{ height: "64px" }}
              >
                <h4> {dataProduct?.model?.product?.sku} </h4>
                <h4>
                  {" "}
                  {dataProduct?.presentation?.is_active ? (
                    <Typography sx={{ color: "green", fontSize: 15 }}>
                      Activo
                    </Typography>
                  ) : (
                    <Typography sx={{ color: "red", fontSize: 15 }}>
                      Desactivado
                    </Typography>
                  )}{" "}
                </h4>
              </Grid>
            </Grid>

            <Grid item xs={12} mt={"32px"}>
              {dataProductAva && !loading && (
                <GenericTable
                  titles={
                    type == "sales"
                      ? [
                          "Presentación",

                          "Estatus",
                          "Ord.",
                          "Disp.",
                          "Res.",
                          "Precio",
                          "Cantidad",
                          "Acción",
                        ]
                      : [
                          "Presentación",

                          "Estatus",
                          "Ord.",
                          "Disp.",
                          "Res.",
                          "Costo",
                          "Cantidad",
                          "Acción",
                        ]
                  }
                >
                  <Row
                    setOpen={setOpenEdit}
                    row={dataProductAva}
                    type={type}
                    dataProduct={dataProduct}
                    orderData={orderData}
                    reloadDataOforder={reloadDataOforder}
                  ></Row>
                </GenericTable>
              )}
              {!dataProductAva && openEdit && !loading && (
                <GenericEmptyTable msg={"No hay datos en la tabla"} />
              )}
              {loading && (
                <Grid xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%", width: "100%" }}
                  >
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </TableModal>
      }
    </>
  );
}

function Row(props) {
  const { row, type, reloadDataOforder, dataProduct, setOpen } = props;

  console.log("row", row);
  console.log("data product", dataProduct);

  /* const [open, setOpen] = useState(false); */
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [presentation, setPresentation] = useState(
    row.product_model[0].presentations?.find(
      (x) => x.id == dataProduct.presentation.id
    )
  );

  const formik = useFormik({
    initialValues: initOrderPurchaseANDSales(dataProduct, type),

    validationSchema: Yup.object(
      valSchOrderPurchaseANDSales(type, presentation)
    ),
    onSubmit: async (formData, { resetForm }) => {
      /*       formik.values.presentation = row.presentation.id;
      formik.values.model = dataProduct.product_model.id; */
      //resetForm();
      setLoading(true);
      console.log(formData);
      if (type == "purchase") {
        //quantity
        /* formik.values.cost = row.presentation.price; */
        formik.values.purchase = Number(id);
        let addToPurchases = new InventoryService();
        let response = await addToPurchases.editDataAuth(
          dataProduct.id,
          formData,
          logout
        );
        /*    if (response.data) {
          sendNotification(UseerrorOrsuccess(response));
        } else {
          sendNotification(UseerrorOrsuccess(response));
        } */
        reloadDataOforder();
      } else {
        formik.values.sale = Number(id);
        /* formik.values.price = row.presentation.price; */
        let addToPurchases = new InventoryServiceSalesOrder();
        let response = await addToPurchases.editDataAuth(
          dataProduct.id,
          formData,
          logout
        );
        reloadDataOforder();
      }
      //udateProducts();
      setOpen(false);
      setLoading(false);
    },
  });

  async function getinfo() {}

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
      <TableCell align="left">
        {/*  <CardHeader
          sx={{m:0, p:0}}
            title={
              <Typography  component="div" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.model.product.name}
            </Typography>}
            subheader={ row.model.name +' '+ row.presentation.name }
          /> */}
        <Typography
          component="div"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {presentation?.name}
        </Typography>
      </TableCell>

      <TableCell align="center" /* sx={{maxWidth: '120px'}} */>
        <Typography variant="subtitle2" component="div">
          {presentation?.is_active ? (
            <CircleIcon sx={{ color: "green", fontSize: 15 }} />
          ) : (
            <CircleIcon sx={{ color: "red", fontSize: 15 }} />
          )}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          component="div"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {presentation?.ordered}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          component="div"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {presentation?.available}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          component="div"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {presentation?.reserved}
        </Typography>
      </TableCell>
      <TableCell align="left" /* sx={{maxWidth: '200px'}} */>
        <form onSubmit={formik.handleSubmit}>
          {type == "sales" && (
            <TextField
              fullWidth
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
              label="Precio"
              variant="outlined"
              autoComplete="none"
              size="small"
              helperText={formik.touched.price ? formik.errors.price : ""}
            />
          )}
          {type == "purchase" && (
            <Typography
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {presentation?.cost}
            </Typography>
          )}
        </form>
      </TableCell>

      <TableCell align="left">
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            name="quantity"
            value={formik.values.quantity}
            onChange={formik.handleChange}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            label="Cantidad"
            variant="outlined"
            autoComplete="none"
            size="small"
            helperText={formik.touched.quantity ? formik.errors.quantity : ""}
          />

          {/* <TextField
                fullWidth
                name="password2"
                values={formik.values.password2}
                onChange={formik.handleChange}
                error={
                  formik.touched.password2 && Boolean(formik.errors.password2)
                }
                label="Verificar contraseña"
                margin="dense"
                variant="outlined"
                autoComplete ='none'
                size="small"
                helperText={
                  formik.touched.password2 ? formik.errors.password2 : ""
                }
              /> */}
        </form>
      </TableCell>
      <TableCell align="left">
        <form onSubmit={formik.handleSubmit}>
          {type === "sales" && (
            <LoadingButton
              loading={false}
              fullWidth
              type="submit"
              variant="contained"
              /*   disabled={
                formik.values.quantity > row.available || row.available === 0
              } */
            >
              Guardar
            </LoadingButton>
          )}
          {type === "purchase" && (
            <LoadingButton
              loading={false}
              fullWidth
              type="submit"
              variant="contained"
            >
              Guardar
            </LoadingButton>
          )}
        </form>
      </TableCell>
    </TableRow>
  );
}
