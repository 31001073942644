import { BASE_PATH } from "../../utils/constants";
import { BaseService } from "base-service-fran-dev";

export class HomeHeader extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "landing/home/header";
    super(baseUrl, endPoint);
  }
}

export class HomeBrief extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "landing/home/brief";
    super(baseUrl, endPoint);
  }
}

export class LandingLogos extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "landing/logos/logo";
    super(baseUrl, endPoint);
  }
}

export async function headePUTTEST(formData, token) {
  try {
    const url = `${BASE_PATH}/landing/home/header`;
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `jwt ${token}`,
      },
      body: JSON.stringify(formData),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export class LandingImages extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "landing/photos/random";
    super(baseUrl, endPoint);
  }
}
export class AiIntegration extends BaseService {
  constructor() {
    const baseUrl = BASE_PATH;
    const endPoint = "landing/ai/integration";
    super(baseUrl, endPoint);
  }
}
