import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

export default function BasicPopover({ children, title }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="text" sx={{ color: "white", height: "45px", textTransform: 'none' }} onClick={handleClick}>
        {title} {open ? <KeyboardArrowUpOutlinedIcon sx={{ color: "white" }} /> : <KeyboardArrowDownOutlinedIcon sx={{ color: "white" }} />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{
          width: "180px",
          height: "80px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Asegura que el contenido esté centrado verticalmente en el espacio del Box
          alignItems: "flex-start", // Alinea el contenido (botón, etc.) a la izquierda
          paddingLeft: "10px",      // Ajusta el espacio desde la izquierda
          textAlign: "left",        // Asegura que el texto esté alineado a la izquierda
        }}
        >
          {children}
        </Box>
      </Popover>
    </div>
  );
}
