import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import InicioCard from './CarImg';
import { getAppsCars, getAppsPos } from '../../api/data/inicioData';
import '../../scss/home.scss'
import { BusinessGroups } from "../../api/businessGroups";
import { useAuth } from "user-context-fran-dev";
import UserManages from "../../api/userManages";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Homenotifications from './homeNotifications';
import { Notifications } from "../../api/common/notification";
import { BottonModalDiag, ModalRight } from "../../components/GenericsModals";
import { Grid, Divider, Button, Modal, List, IconButton } from '@mui/material'
import appStore from "../../assets/apple-icon.svg"
import playStore from "../../assets/google-play-short.png"
import { motion } from "framer-motion"
import Settings from "../../assets/settings.png";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Link, useSearchParams } from "react-router-dom";
import CashRegisterPos from "../pos/cashRegister/cashRegister";
import AddCashRegister from "../pos/cashRegister/addCashRegister";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import googleplay from './../../assets/GooglePlay.png'
import appstore from './../../assets/Appstore.png'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import GenericList, { DataPrompts } from "../../components/GenericList";
import { Description } from "@mui/icons-material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//import admin from './images/administracion.png'

const InicioApps = ({ admin }) => {
  const [appsGroup, setAppsGroup] = useState("");
  const { logout, auth } = useAuth();
  const [user, setUser] = useState("");
  const [noty, setNoty] = useState("")
  const [open, setOpen] = useState(false)
  const [openModalPos, setOpenModalPos] = useState(false)
  const [type, setType] = useState()
  const [view, setView] = React.useState(1);
  const [params, setParams] = useSearchParams('');
  //Modal right state
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("initial");
  const [id, setId] = useState(0);
  const [showId, setShowId] = useState(0);
  const [activeData, setActiveData] = useState(0);
  const data = getAppsCars();

  // console.log('activeData', activeTab)
  // console.log("-------", id)
  // console.log("-------", activeTab)
  // console.log("-------", showId)

  {/* POSIntegration This section has been added to have the pos integrated in the admin project */ }
  const posData = getAppsPos();


  const size = {
    maxWidth: 295,
    minWidth: 295,
    maxHeight: 300,
    margin: '10px',
  };

  useEffect(() => {

    // console.log('posdata', posData)

    setParams(params)
    UpdateAppAcces();
    GetCurrentUser();
    GetNotifications();

  }, []);


  const GetNotifications = () => {
    (async () => {
      let irequestFilter = [];
      irequestFilter.push({ 'key': 'is_active', 'value': "true" })
      const getNoti = new Notifications();
      const response = await getNoti.filter(irequestFilter, logout);
      // console.log(response.data)
      setNoty(response.data)

    })();
  };

  const GetCurrentUser = () => {
    (async () => {
      const getUser = new UserManages();
      const response = await getUser.getAllByIdAuth(auth.user.pk, logout);
      // console.log(response.data)
      setUser(response.data)
    })();
  };

  const UpdateAppAcces = () => {
    (async () => {
      let getBranchs = new BusinessGroups();
      let response = await getBranchs.getAllAuth(logout);
      setAppsGroup(response.data);

    })();
  };

  {/*} const TypeModal = (type) => {
    setType(type)
    if (type === 'POS')
      setOpen(true)
    else if (type === 'cashRegister')
      setOpenModalPos(true)
  };*/}

  const handleClickOpenModal = () => {
    setOpenModal(true);
  }
  const handleChangeData = (data) => {
    setActiveData(data);
  }

  const TypeModal = () => {
    setOpenModalPos(true);
  };

  const handleCloseModal = () => {
    setOpenModalPos(false);
  };

  const handleChange = (event) => {
    params.set("tab", `${event.target.value}`);
    setView(event.target.value)
    setParams(params);
  };

  const item = [
    {
      title: "Prompts personalizados",
      icon: <ChatBubbleOutlineIcon />,
      id: 1,
      url: "/pos/login/"
    },
    {
      title: "Inventario",
      icon: <AssignmentTurnedInOutlinedIcon />,
      id: 2,
      url: "/pos/login/"
    },
    {
      title: "Tienda en línea",
      icon: <StorefrontOutlinedIcon />,
      id: 3,
      url: "/pos/login/"
    },
    {
      title: "Punto de venta",
      icon: <LocalGroceryStoreOutlinedIcon />,
      id: 4,
      url: "/pos/login/"
    },

  ]
  const item2 = [
    {
      title: "Analizar las tebdencias de la industria",
      id: 1,
    },
    {
      title: "Análisis competitivo",
      id: 2,
    },
    {
      title: "Análisis de mercado",
      id: 3,
    },
    {
      title: "Análisis de la cadena de valor",
      id: 4,
    },

  ]
  const item3 = [
    {
      id: 1,
      title: "Analizar las tebdencias de la industria",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat,"
    },
  ]

  return (

    <div className="contenedor-apps">
      {/* <HelperModal open={open} setOpen={setOpen} type={type} />*/}
      <ModalPos openModalPos={openModalPos} setOpenModalPos={setOpenModalPos} handleCloseModal={handleCloseModal}></ModalPos>
      <Grid container direction={{ xs: "column", md: "row" }} justifyContent={{ md: "start" }} alignItems="center" sx={{ mb: '16px' }} >
        <Grid md={4} sx={{ marginBottom: '16px', mr: '16px' }} >
          <Typography
            variant="h6"
            component="div"
            sx={{ color: "text.primary" }}
          >
            <strong>Tus aplicaciones</strong>
          </Typography>
        </Grid>
        {/* <Grid md={4} sx={{ marginBottom: '16px' }} >
          <Button variant="outlined" onClick={() => {
            setActiveTab("initial");
            handleClickOpenModal();
          }} sx={{ width: '100%' }}>Abrir caja</Button>
          <ModalRight
            open={openModal}
            setOpen={setOpenModal}
            title={<Typography variant="h6" sx={{ fontWeight: 'bold', }}>Prompts</Typography>}
            size="sm"
          >
            <Divider />
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '16px' }}>
              <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                {activeTab === "initial" ?
                  //Initial tab
                  <>
                    <GenericList item={item} handleChangeTab={() => setActiveTab("list")} handleChangeId={setId} />
                  </>
                  : activeTab === "list" ?
                    //Propmt personalizados
                    <Box sx={{ width: '100%', }}>
                      <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <Typography variant="h6" component="div" sx={{ color: "text.primary" }}>
                          <IconButton onClick={() => setActiveTab("initial")} > <ArrowBackIosNewIcon /> </IconButton>
                          <strong>Prompts tienda en linea </strong>
                        </Typography>
                      </Box>
                      <GenericList item={item2} handleChangeTab={() => setActiveTab("show")} handleChangeId={setShowId} />

                    </Box>
                    :
                    //Show prompts
                    <Box sx={{ width: '100%', }}>
                      <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <Typography variant="h6" component="div" sx={{ color: "text.primary" }}>
                          <IconButton onClick={() => setActiveTab("list")} > <ArrowBackIosNewIcon /> </IconButton> <strong> Show props </strong>
                        </Typography>
                      </Box>
                      <DataPrompts data={item3} />
                    </Box>
                }
              </Grid>

            </Grid>

          </ModalRight>
        </Grid> *}



        {/* <Grid xs={6} sx={{ marginBottom: '16px' }}>

          <Box sx={{ minWidth: 120 }}>
            <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Vista</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={view}
                label="Vista"
                onChange={handleChange}
                sx={{ width: '200px' }}
              >
                <MenuItem value={1}>Admin</MenuItem>
                <MenuItem value={2}>Punto de venta</MenuItem>
              </Select>
            </FormControl>
          </Box>
  </Grid>*/}
      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="start" m={3}>
        <Grid container justifyContent="flex-start" spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 4, md: 12 }} >
          {appsGroup && user ?
            data.map((apps, index) => {
              return (
                <Grid padding={{ xs: '10px', md: '16px' }}>
                  <InicioCard
                    data={apps}
                    key={index}
                    TypeModal={TypeModal}
                    buy={appsGroup.find((data) => apps.id === data.id ) !== undefined}
                    access={user.groups ? user.groups.find((data) => apps.id === data.id) : false}
                  />
                </Grid>
              )
            })

            : (<Box sx={{
              position: 'absolute', top: '40%',
              left: '50% ', with: '100vw', mt: '16px'
            }}>
              <CircularProgress />
            </Box>)}
        </Grid>
      </Grid>

      <Homenotifications noty={noty} />




    </div>
  );
}

{/*const HelperModal = ({ open, setOpen, type }) => {
  console.log('width', window.innerWidth)
  console.log('width', window.innerHeight)
  let title = ""
  if (type === "POS") {
    title = "Punto de venta"
  } else {
    title = "Próximamente"
  }
  if (window.innerWidth <= 768) { //tablet width
    return (
      <BottonModalDiag open={open} setOpen={setOpen} title={{ "text": title, "align": "center" }}>
        {type === "pos" ?
          <Grid container>
            <Grid item xs={7}>
              <Link to={"/pos/login/"} target={"_blank"} style={{ textDecoration: "none" }}>
                <Button startIcon={<OpenInNewIcon />} >Abrir en el navegador</Button></Link>
            </Grid>
            <Grid item xs={12} mt="16px">
              <Typography>Descargar en:</Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                m: 'auto',
                alignItems: "center",
                justifyContent: "start",
                width: '100%',
              }}>


                <a href="https://apps.apple.com/us/app/keski-pos/id1661375259" target="_blank">
                  <img src={appStore} style={{ width: "150px", height: "45px", cursor: "pointer" }}></img>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.codenationstudio.app.keski_pos" target="_blank">
                  <img src={playStore} style={{ width: "150px", height: "60px", cursor: "pointer" }}></img>
                </a>
              </Box>
            </Grid>
          </Grid> :
          <>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              alignItems: "center",
              justifyContent: "center",
              width: 'auto',
              height: "auto"
            }}>

              <motion.div

                animate={{

                  rotate: [0, 360],

                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "linear",
                  repeatDelay: 0

                }}
              >   <img src={Settings} style={{ width: "50px", height: "50px" }}></img> </motion.div>
              <motion.div
                style={{}}
                initial={{ x: 20, y: -9, rotate: 25 }}
                animate={{

                  rotate: [360, 0],

                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "linear",
                  repeatDelay: 0
                }}
              >
                <img src={Settings} style={{ width: "50px", height: "50px" }}></img>
              </motion.div>

            </Box>
          </>}
      </BottonModalDiag>
    )
  }
}*/}

{/*
const HelperModalCash = ({ openCashReg, setOpenCashReg }) => {
  let title = "Cajas abiertas"
  const [cashReload, setReload] = useState(false)
  return (
    <BottonModalDiag open={openCashReg} setOpen={setOpenCashReg} title={{ "text": title, "align": "start" }}>
      <Grid container direction="row" justifyContent="start">
        <Grid xs>

          <CashRegisterPos setReload={setReload} cashReload={cashReload} setHelpOpen={setOpenCashReg}></CashRegisterPos>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid xs>
          <AddCashRegister setReload={setReload} cashReload={cashReload}></AddCashRegister>
        </Grid>
      </Grid>
    </BottonModalDiag>
  )
}
*/}


const ModalPos = ({ openModalPos, setOpenModalPos, handleCloseModal }) => {
  return (
    <ModalRight
      title={<strong>Punto de venta</strong>}
      open={openModalPos}
      setOpen={setOpenModalPos}
    >
      <Grid container>
        <Grid item xs={12} sx={{ paddingTop: '30px' }}>
          <a href="https://pos.keskinube.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <Button endIcon={<OpenInNewIcon />} >
              Abrir en el navegador
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} mt="26px">
          <Typography sx={{ paddingLeft: '7px' }}>Descargar la app:</Typography>
          <Grid item xs={12} mt="16px">
            <a href="https://apps.apple.com/us/app/keski-pos/id1661375259" target="_blank">
              <img src={appstore} style={{ width: "200px", height: "80px", cursor: "pointer" }}></img>
            </a>
          </Grid>
          <Grid item xs={12} mt="">
            <a href="https://play.google.com/store/apps/details?id=com.codenationstudio.app.keski_pos" target="_blank">
              <img src={googleplay} style={{ width: "185px", height: "72px", cursor: "pointer", paddingLeft: '8px' }}></img>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid container >
        <Grid
          sx={{
            position: 'absolute',
            bottom: 50,
            left: { xs: 250, sm: 300 }, // 250 for xs devices, 300 for sm and larger
          }}>
          <Button variant="outlined" sx={{ width: '100%' }} onClick={handleCloseModal}>Cerrar</Button>
        </Grid>
      </Grid>
    </ModalRight>
  )
}


export default InicioApps;