import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../scss/animations.scss";
import CategoryManages from "../../api/categoryManages";
import { useAuth } from "user-context-fran-dev";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { Suppliers } from "../../api/supplierService";

/* Nota de uso 
    Agregar donde se vaya a usar 
      const [filters, setFilters] = useState("")
      y
        if(filters){
          for(let element in filters){
            if(filters[element]){
                irequestFilter.push({'key':`${element}`, 'value': `${filters[element]}`})
            }
          }
        } 

        <SearchFilters filters={filters} setFilters={setFilters}/>
              irequestFilter.push({'key':'is_active', 'value': `true`}) 
 */
const SearchFilters = ({ filters, setFilters }) => {
  const [advanced, setAdvanced] = useState(false);
  const [type /* setType */] = useState([
    "Almacenable",
    "Servicio",
    "Consumible",
  ]);
  const [category, setCategory] = useState("");
  const [supplier, setSupplier] = useState("");
  const { logout } = useAuth();

  useEffect(() => {
    if (advanced) {
      Category();
      SuppliersList();
      setFilters({ category: "", supplier: "", type: "" });
    }
    if (!advanced) {
      setTimeout(() => {
        setFilters("");
      }, 500);
    }
  }, [advanced]);

  const Category = () => {
    (async () => {
      const getList = new CategoryManages();
      const response = await getList.getAllAuth(logout);
      setCategory(response.data);
      /* setList({...list, "category": response.data}) */
      /* console.log(response.data) */
    })();
  };
  const SuppliersList = () => {
    (async () => {
      let Temp = [];
      const getList = new Suppliers();
      const response = await getList.getAllAuth(logout);
      Temp.push({ label: "Todo", id: "" });
      response.data.forEach((element) => {
        Temp.push({ label: element.business_name, id: element.id });
      });
      setSupplier(Temp);
    })();
  };

  const setChangesList = (e, newValue) => {
    if (newValue) {
      setFilters({ ...filters, ["supplier"]: newValue.id });
      return;
    }
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Grid item xs={12} sm={4} ml={{ xs: 0, sm: "16px" }}>
        <Button
          onClick={() => setAdvanced(!advanced)}
          disabled={!advanced && filters ? true : false}
          variant="text"
        >
          Búsqueda avanzada
        </Button>
      </Grid>

      {filters != "" && (
        <Grid
          container
          /* mt={ advanced? '16px': "0px" } */ spacing={"8px"}
          className={advanced ? "slide-in-left" : "slide-out-left"}
        >
          <Grid item xs={6} sm={4} mt={"16px"}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="demo-simple-select-filled-label">Tipo</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                label={"Tipo"}
                name="type"
                value={filters.type}
                onChange={(e) => setChangesList(e)}
              >
                <MenuItem value={""}>Todo</MenuItem>
                {type.map((data, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4} mt={"16px"}>
            {category && (
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-filled-label">
                  Categoría
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  label={"Categoría"}
                  name="category"
                  value={filters.category}
                  onChange={(e) => setChangesList(e)}
                >
                  <MenuItem value={""}>Todo</MenuItem>
                  {category.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          {supplier && (
            <Grid item xs={6} sm={4} mt={"16px"}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={supplier}
                fullWidth
                name="supplier"
                value={filters.supplier.label}
                onChange={(e, newValue) => setChangesList(e, newValue)}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Proveedor" name="supplier" />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default SearchFilters;
