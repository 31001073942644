"use client"
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
//import { Colors } from "../../styles/theme";
//import drawing_pencil from "../../assets/Json/edit_view.json";
//import Lottie from 'react-lottie';


const GenericSection = ({ title, description }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        //animationData: drawing_pencil,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
            <Box sx={{ width: '80%', textAlign: 'center', padding: '16px 0px 0px 0px' }}>
                <Typography variant="h3"
                    component="h2"
                    gutterBottom
                    color="primary"
                >
                    {title}
                </Typography>
                <Typography
                    gutterBottom
                    sx={{}}>
                    {description}
                </Typography>
                {/*<Lottie options={defaultOptions} width={'150px'} height={'150px'} />*/}
                <Typography
                    gutterBottom
                    sx={{}}>Ingresa a Keski Creator desde tu panel administrativo para editar esta vista.
                </Typography>
            </Box>
        </Container>
    );
};

GenericSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default GenericSection;