import { Grid, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useState } from "react";

export const ImageContainer = ({
  images,
  savedImages = false,
  saveNewImage,
  deleteImage,
  searchMoreImages,
}) => {
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        height: "800px",
        overflowY: "scroll",
        paddingBottom: "150px",
      }}
    >
      {!savedImages && (
        <Grid
          item
          xs={5}
          sx={{
            p: "2px 4px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            borderRadius: "5px",
            border: 1,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder=""
            inputProps={{ "aria-label": "search images" }}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => searchMoreImages(search)}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
      )}
      <Grid
        container
        xs={12}
        sx={{ marginTop: savedImages ? "40px" : "0px" }}
        spacing={2}
      >
        {images.map((image) => (
          <ImageEdition
            {...image}
            savedImages={savedImages}
            deleteImage={deleteImage}
            saveNewImage={saveNewImage}
          />
        ))}
      </Grid>
    </div>
  );
};

export const ImageEdition = ({
  id,
  url,
  savedImages,
  deleteImage,
  saveNewImage,
}) => {
  return (
    <Grid item xs={3}>
      <Grid container>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <img
            src={url}
            alt=""
            loading="lazy"
            style={{ height: "310px", width: "100%", objectFit: "cover" }}
          />
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              width: "25px",
              height: "25px",
              borderRadius: "50px",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {savedImages ? (
              <DeleteIcon
                sx={{ width: "18px", cursor: "pointer" }}
                onClick={() => deleteImage(id)}
              />
            ) : (
              <FileUploadIcon
                sx={{ width: "18px", cursor: "pointer" }}
                onClick={() => saveNewImage(url)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
