import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import BasicTable from "../../components/GenericTable";
import Container from "../../layout/gridContainer";
import PurchasesTable from "./purchase/purchasesTable";
import AddPurchase from "./purchase/addPurchase";
import PurchaseService from "../../api/purchaseService";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import GenericEmptyTable from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import useNexOrder from "../../hooks/nextOrder";
import { useAuth } from "user-context-fran-dev";
import MuiContainer from "@mui/material/Container";

const InventoryPurchase = () => {
  const [openModal, setOpenModal] = useState();
  const [noItems, setNoItems] = useState(false);
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(false);
  const [idOrder] = useNexOrder(table);
  const { logout } = useAuth();
  const totalPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`page=${page}`);
    UpdatePurchaseList();
  }, [page]);

  const UpdatePurchaseList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getPurchases = new PurchaseService();
      let response = await getPurchases.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      console.log("response oreders list ", response);
      if (response.status != 200) {
        setNoItems(true);
      }
      setLoading(false);
    })();
  };

  const pageSetter = (page) => {
    setPage(page);
    /* navigate(`page=${page}`) */
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="title-card"
            sx={{ paddingBottom: "2%" }}
          >
            <strong>Compras</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <GenericSearch
                Service={PurchaseService}
                setData={setTable}
                label={"Buscar por numero de orden"}
              ></GenericSearch>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    fullWidth
                    variant="contained"
                  >
                    {" "}
                    Agregar compra{" "}
                  </Button>
                  {idOrder && (
                    <AddPurchase
                      openModal={openModal}
                      UpdatePurchaseList={UpdatePurchaseList}
                      setOpenModal={setOpenModal}
                      idOrder={idOrder}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {table && (
            <>
              <BasicTable
                className="marginTable"
                titles={[
                  "Num. Orden / Fecha",
                  "Proveedor/Almacén",
                  "Tipo de Orden",
                  "Estatus",
                  "Total",
                  "Más",
                ]}
              >
                {table.map((data, index) => (
                  <PurchasesTable key={index} data={data} />
                ))}
              </BasicTable>
            </>
          )}
          {loading && (
            <Grid container justifyContent="center" mt={5}>
              <CircularProgress />
            </Grid>
          )}
          {!table && !loading && (
            <GenericEmptyTable msg={"No hay datos en la tabla"} />
          )}
          <div className="margin">
            <PaginationGeneric
              className="paginationStyle"
              pagina={1}
              total={totalpages}
              pageSetter={pageSetter}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InventoryPurchase;
