import { useSnackbar } from 'notistack';

import { useEffect, useState } from "react";


export const useNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, /* closeSnackbar */ } = useSnackbar();

    useEffect(() => {

        if (conf) {
            console.log(conf)
        }
        if (conf.custom) {
            enqueueSnackbar(conf.custom, {
                variant: conf.variant,
                //autoHideDuration: 1000,
                //action
            });
        }
        if (conf.msg?.status == 500) {
            console.log('Entró en el if de status 500');
            enqueueSnackbar('Ocurrió un error interno en el servidor', {
                variant: 'error',
            });
        } else {
            if (conf.msg && conf.variant) {
                console.log('Entró en el if de conf.msg y conf.variant', conf);
                if (conf.variant == 'success') {
                    console.log('Entró en el success del hook');
                    enqueueSnackbar(conf.msg, {
                        variant: 'success',
                        //autoHideDuration: 1000,
                        //action
                    });
                } else if (conf.msg.data?.error) {
                    console.log('Entró en el error del hook');
                    enqueueSnackbar(conf.msg.data.error, {
                        variant: 'error',
                        //autoHideDuration: 1000,
                        //action
                    });
                    if (conf.msg.data?.error_code) {
                        console.log('Entró en el if de error_code');
                        for (var key in conf.msg.data.error_code) {
                            conf.msg.data.error_code[key].forEach(function (elemento) {
                                console.log('Elemento de error_code:', elemento);
                                enqueueSnackbar(elemento, {
                                    variant: 'error',
                                    //autoHideDuration: 1000,
                                    //action
                                });
                            });
                        }
                    }
                } else {
                    console.log('Entró en el else para iterar sobre conf.msg.data');
                    for (var key in conf.msg?.data) {
                        if (Array.isArray(conf.msg.data[key])) {   //Comprobamos si lo que regresa es un arreglo de datos
                            conf.msg.data[key].forEach(function (elemento) {
                                console.log('Elemento de data:', elemento);
                                enqueueSnackbar(elemento, {
                                    variant: 'error',
                                    //autoHideDuration: 1000,
                                    //action
                                });
                            });
                        } else {
                            enqueueSnackbar(conf.msg.data[key], { //Sino, mostrara directamente el snackbar
                                variant: 'error',
                                //autoHideDuration: 1000,
                                //action
                            });
                        }
                    }
                }
            }
        }
    }, [conf]);

    return [conf, setConf];
};

export const UseerrorOrsuccess = (response) => {

    console.log('Entre al UserErrorSucces', response)
    if (response.status >= 400) {
        console.log('data', response);
        return { msg: response, variant: 'error' }
    } else if (response.status >= 300 && response.status < 400) {
        console.log('data', response);
        return { msg: response, variant: 'warning' }
    } else {
        console.log('Entre a la operacion exitosa')
        return { msg: 'Operacion exitosa', variant: 'success' }
    }

}
