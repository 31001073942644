import React from "react";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const GenericHeaderTitle = (props) => {
  const { title, children, size } = props;
  const maxWidth = size ? size : "100%";

  return (
    <Paper
      sx={{
        padding: "8px 16px",
        backgroundColor: "background.header",
        maxWidth,
      }}
    >
      <Grid container>
        <Grid item xs={6} sm={9} md={9}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Typography variant="h1" fontSize={"20px"}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Grid
            container
            justifyContent="end"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            {children && children}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const GenericHeaderExpandable = (props) => {
  const { title, arrow, setArrow, children } = props;
  return (
    <Paper>
      <Paper sx={{ padding: "8px 16px", backgroundColor: "background.header" }}>
        <Grid container>
          <Grid item xs={9} sm={6}>
            <Grid container justifyContent="start" alignItems="center">
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={6}>
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <IconButton
                sx={{ margin: 0, padding: 0 }}
                onClick={() => {
                  setArrow(!arrow);
                }}
              >
                {arrow ? (
                  <KeyboardArrowUpIcon
                    fontSize="small"
                    sx={{ color: "text.primary", margin: 0, padding: 0 }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{ color: "text.primary", margin: 0, padding: 0 }}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Collapse in={arrow} timeout={500} unmountOnExit>
        <Grid item xs={12} sx={{ padding: "16px 16px" }}>
          {children}
        </Grid>
      </Collapse>
    </Paper>
  );
};

export const GenericHeaderSubTitle = (props) => {
  const { title } = props;

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        sx={{
          display: "flex",
          alignItems: "flex-start",
          textAlign: "left",
          margin: "16px 0px",

          "&:after": {
            content: "''",
            flexGrow: "1",
            borderRadius: "5px",
            height: "4px",
            backgroundColor: "background.header",
            minWidth: "20px",
            margin: "auto",
            marginTop: "16px",
            marginLeft: "20px",
          },
        }}
      >
        <strong>{title}</strong>
      </Typography>
    </>
  );
};
