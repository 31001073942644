import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import GenericSizeModal from "../../components/GenericSizeModal";
import { Suppliers } from "../../api/supplierService";
import { useAuth } from "user-context-fran-dev";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import PurchaseService from "../../api/purchaseService";
import SalesService from "../../api/salesService";
import CustomerService from "../../api/customerService";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../hooks/snackBarResponse";
import GenericPaymentConditions from "../GenericPaymentConditions";
import CurrencyFormat from "react-currency-format";
import GenericEmptyTable from "../../components/GenericEmptyTable";
import { ArticleExpandableView } from "./ArticleExpandableView";
import { GenericHeaderTitle } from "../GenericHeader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { GenericDrawer } from "../keskiCreator/genericDrawer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const Item = styled(Paper)(({ theme }) => ({
  margin: "16px 0",
  padding: "8px",
}));

const InventoryGenericDetailsView = (props) => {
  const { name, pay, data, children, type, reloadDataOforder, status } = props;
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState("");
  const [dataList, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, sendNotification] = useNotification();
  const [supplier, setSuplierid] = useState("Proveedor");
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
    supplierList();
  };

  const handleChange = (event) => {
    console.log("evento", event);
    setSuplierid(event.target.value);
    setClient(event.target.value);
  };

  const openModalSuppliers = () => {
    supplierList();
  };

  const openModalClients = () => {
    clientList();
  };

  const supplierList = () => {
    (async () => {
      let getUsers = new Suppliers();
      let response = await getUsers.getAllAuth(logout);
      console.log("suppliers", response);
      setData(response.data);
      setOpen(true);
    })();
  };

  const clientList = () => {
    (async () => {
      let getClients = new CustomerService();
      let response = await getClients.getAllAuth(logout);
      console.log("clients", response);
      setData(response.data);
      setOpen(true);
    })();
  };

  const editSupplier = () => {
    (async () => {
      //setLoading(true)
      let getPurchases = new PurchaseService();
      let response = await getPurchases.editDataAuth(
        data.id,
        { supplier: supplier },
        logout
      );
      console.log(response);
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
      setOpen(false);
      setLoading(false);
    })();
  };

  const editClient = () => {
    (async () => {
      setLoading(true);
      let getPurchases = new SalesService();
      let response = await getPurchases.editDataAuth(
        data.id,
        { client: client },
        logout
      );
      console.log(response);
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
      setOpen(false);
      setLoading(false);
    })();
  };

  return (
    <>
      {data && (
        <>
          <GenericPaymentConditions
            data={data}
            open={openDrawer}
            toggleDrawer={toggleDrawer}
            UpdateInfo={reloadDataOforder}
            target={type}
          />
          {type === "sales" ? (
            <>
              <GenericSizeModal
                open={open}
                setOpen={setOpen}
                title={"Seleccionar Cliente"}
              >
                <Grid item xs>
                  <Grid item xs={12}>
                    {dataList ? (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Categorias"
                        fullWidth
                        name="client"
                        value={client}
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>Clientes</em>
                        </MenuItem>
                        {dataList
                          ? dataList.map((data, key) => (
                              <MenuItem key={key} value={data.id}>
                                <a>{data.business_name}</a>
                              </MenuItem>
                            ))
                          : ""}
                      </Select>
                    ) : (
                      <GenericEmptyTable
                        msg={"No hay clientes en el registro"}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs></Grid>
                    <Grid container justifyContent="flex-end">
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ marginTop: "15px" }}
                      >
                        <LoadingButton
                          loading={loading}
                          fullWidth
                          onClick={editClient}
                          type="submit"
                          variant="contained"
                          disabled={!dataList}
                          sx={{ color: "#fff" }}
                        >
                          Agregar
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </GenericSizeModal>
              <Grid container spacing={3}>
                <Grid item xs>
                  {/*---- Proveedor Editor---------------------------------> */}
                  <GenericHeaderTitle title={name}>
                    {data.status === 1 && (
                      <EditOutlinedIcon
                        disabled={true}
                        sx={{ cursor: "pointer" }}
                        onClick={() => toggleDrawer("right1", true)}
                      />
                    )}
                  </GenericHeaderTitle>

                  <Grid item xs={12}>
                    <div className="bussines-name">
                      <h2>
                        {data.client?.business_name
                          ? data.client.business_name
                          : "Nombre"}
                      </h2>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {data.client?.rfc ? data.client.rfc : "RFC"}
                    </Typography>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item className="phone" pr="8px">
                      <LocalPhoneOutlinedIcon />
                    </Grid>
                    <Grid item xs>
                      {data.client ? (
                        <CurrencyFormat
                          value={data.client.phone}
                          displayType={"text"}
                          format="(##) #### ####"
                        />
                      ) : (
                        <a>Telefono</a>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item className="phone" pr="8px">
                      <AlternateEmailIcon />
                    </Grid>
                    <Grid item xs>
                      <a>{data.client?.email ? data.client.email : "Email"}</a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <GenericHeaderTitle title={pay}>
                    {data.status === 1 && (
                      <>
                        <EditOutlinedIcon
                          disabled={true}
                          sx={{ cursor: "pointer" }}
                          onClick={() => toggleDrawer("right", true)}
                        />
                      </>
                    )}
                  </GenericHeaderTitle>

                  <Grid item xs={12}>
                    <div className="bussines-name"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={5} className="phone">
                        <a>Días de crédito:</a>
                      </Grid>
                      <Grid item xs>
                        <Grid container justifyContent="flex-start">
                          {data?.credit_days ? data.credit_days : ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {type !== "sales" && (
                    <Grid item xs={12}>
                      <Grid item container direction="row">
                        <Grid item xs={5} className="phone">
                          <a>Límite de crédito:</a>
                        </Grid>
                        <Grid item xs>
                          <Grid container justifyContent="flex-start">
                            <CurrencyFormat
                              value={
                                data.client?.credit_limit
                                  ? data.client.credit_limit
                                  : ""
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={5} className="phone">
                        <a>Descuento:</a>
                      </Grid>
                      <Grid item xs>
                        <Grid container justifyContent="flex-start">
                          {data?.discount ? data.discount + "%" : ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="row">
                      <Grid item xs={4} className="phone">
                        <a>IVA:</a>
                      </Grid>
                      <Grid item xs>
                        <Grid container justifyContent="flex-start">
                          {data.iva ? data.iva + "%" : ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {/*---- Proveedor Drawer ---------------------------------> */}
              <GenericDrawer
                open={openDrawer}
                toggleDrawer={toggleDrawer}
                anchor={"right1"}
                drawerWidth="30%"
              >
                <Box p={"16px"}>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: "8px",
                    }}
                  >
                    <Typography fontSize={"20px"} p={"8px 0"}>
                      <b>Seleccionar proveedor</b>
                    </Typography>

                    <IconButton onClick={() => toggleDrawer("right1", false)}>
                      <CloseSharpIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <TextField
                      label="Buscar por nombre"
                      size="small"
                      margin="normal"
                      sx={{ width: "60%" }}
                    />
                    <DeleteOutlineOutlinedIcon
                      fontSize="large"
                      sx={{
                        color: "GrayText",
                      }}
                    />
                    <LoadingButton
                      loading={loading}
                      onClick={editSupplier}
                      type="submit"
                      variant="outlined"
                      sx={{ borderRadius: "60px" }}
                    >
                      buscar
                    </LoadingButton>
                  </Grid>
                  <Typography p={"8px 0"}>Resultados</Typography>

                  {dataList ? (
                    (dataList || []).map((data, key) => (
                      <Item key={key} value={data.id} elevation={4}>
                        <Typography>
                          <b>{data.business_name}</b>
                        </Typography>

                        <Grid
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography color="grey">{data.rfc}</Typography>
                          <LoadingButton
                            variant="contained"
                            sx={{ borderRadius: "60px", textTransform: "none" }}
                            size="small"
                          >
                            seleccionar
                          </LoadingButton>
                        </Grid>
                      </Item>
                    ))
                  ) : (
                    <Item elevation={4}>
                      <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography>
                          <b>No hay contenido disponible aun</b>
                        </Typography>
                        <Typography align="center" m={"8px 0"}>
                          Agrega ahora un proveedor para que se muestre aqui
                        </Typography>
                        <LoadingButton
                          variant="contained"
                          sx={{
                            borderRadius: "60px",
                            textTransform: "none",
                            m: "8px 0",
                          }}
                          size="small"
                        >
                          agregar proveedor
                        </LoadingButton>
                        <Typography
                          align="center"
                          m={"8px 0"}
                          fontSize={"15px"}
                        >
                          ¿Necesitas ayuda? Checa nuestro{" "}
                          <Link>contenido para más inspiración</Link>
                        </Typography>
                      </Grid>
                    </Item>
                  )}
                </Box>
              </GenericDrawer>
              <Grid
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                container
                spacing={3}
              >
                <Grid item xs>
                  <GenericHeaderTitle title={name}>
                    {status === 1 && (
                      <>
                        <RemoveRedEyeOutlinedIcon
                          fontSize="small"
                          sx={{ mr: 2 }}
                        />
                        <EditOutlinedIcon
                          disabled={true}
                          sx={{ cursor: "pointer" }}
                          onClick={() => toggleDrawer("right1", true)}
                        />
                      </>
                    )}
                  </GenericHeaderTitle>
                  <Stack p={"16px 16px 0 16px "} spacing={1.5}>
                    <Typography variant="h3" fontSize={"24px"}>
                      {data.supplier
                        ? data.supplier.business_name
                        : "Nombre de la empresa"}
                    </Typography>

                    <Typography fontSize={"16px"}>
                      {data.supplier?.rfc ? data.supplier.rfc : " "}
                    </Typography>

                    <Grid item container direction="row">
                      <LocalPhoneOutlinedIcon sx={{ mr: 1 }} />
                      {data.supplier ? (
                        <CurrencyFormat
                          value={data.supplier.phone}
                          displayType={"text"}
                          format="(##) #### ####"
                        />
                      ) : (
                        <Typography>Telefono</Typography>
                      )}
                    </Grid>
                    <Grid item container direction="row">
                      <AlternateEmailIcon sx={{ mr: 1 }} />

                      <a>
                        {data.supplier
                          ? data.supplier.email
                          : "Email de la empresa"}
                      </a>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs>
                  <GenericHeaderTitle title={pay}>
                    {status === 1 && (
                      <>
                        <EditOutlinedIcon
                          disabled={true}
                          sx={{ cursor: "pointer" }}
                          onClick={() => toggleDrawer("right", true)}
                        />
                      </>
                    )}
                  </GenericHeaderTitle>
                  <Stack spacing={1} pt={"16px"}>
                    <Grid item xs={12}>
                      <Grid item container direction="row">
                        <Grid item xs={4} className="phone">
                          <a>Días de crédito:</a>
                        </Grid>
                        <Grid item xs>
                          <Grid container justifyContent="flex-start">
                            {data.credit_days ? data.credit_days : "0"}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {type !== "purchase" && (
                      <Grid item xs={12}>
                        <Grid item container direction="row">
                          <Grid item xs={4} className="phone">
                            <a>Límite de crédito:</a>
                          </Grid>
                          <Grid item xs>
                            <Grid container justifyContent="flex-start">
                              <CurrencyFormat
                                value={
                                  data.supplier?.credit_limit
                                    ? data.supplier.credit_limit
                                    : "0"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid item container direction="row">
                        <Grid item xs={4} className="phone">
                          <a>Descuento:</a>
                        </Grid>
                        <Grid item xs>
                          <Grid container justifyContent="flex-start">
                            {data?.discount ? data.discount + "%" : "0%"}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item container direction="row">
                        <Grid item xs={4} className="phone">
                          <a>IVA:</a>
                        </Grid>
                        <Grid item xs>
                          <Grid container justifyContent="flex-start">
                            {data.iva ? data.iva + "%" : ""}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={3}>
            <Grid item xs className="table">
              {data.status === 1 && (
                <Grid item xs={12} mb={"16px"}>
                  <ArticleExpandableView
                    type={type}
                    reloadDataOforder={reloadDataOforder}
                    wh={data}
                  ></ArticleExpandableView>
                </Grid>
              )}
              <GenericHeaderTitle title="Artículos" />
              <Grid item xs>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default InventoryGenericDetailsView;
