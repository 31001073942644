import {
  Box,
  Container,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
  styled,
  TextField,
  Typography,
  Button,
  Divider,
  IconButton
} from "@mui/material";
import images from "../../assets/index.js";
import { useEffect, useRef, useState } from "react";
import { AiIntegration } from "../../api/landing/homeLanding.js";
import { ModalRight } from "../../components/GenericsModals.js";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import GenericList, { DataPrompts } from "../../components/GenericList";
import { Description } from "@mui/icons-material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgba(96, 93, 93, 0.25)",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

export const Chat = () => {
  const ia = new AiIntegration();
  const [qa, setQa] = useState("Cuales son los productos mas costosos");
  const [send, setSend] = useState(false);
  const [answer, setAnswer] = useState();

  const getIAResponse = async () => {
    setSend(true);
    try {
      const response = await ia.postData({
        prompt: qa,
        chat: "",
      });
      if (response.status === 200) {
        setAnswer(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("initial");
  const [id, setId] = useState(0);
  const [showId, setShowId] = useState(0);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const item = [
    {
      title: "Prompts personalizados",
      icon: <ChatBubbleOutlineIcon />,
      id: 1,
      url: "/pos/login/"
    },
    {
      title: "Inventario",
      icon: <AssignmentTurnedInOutlinedIcon />,
      id: 2,
      url: "/pos/login/"
    },
    {
      title: "Tienda en línea",
      icon: <StorefrontOutlinedIcon />,
      id: 3,
      url: "/pos/login/"
    },
    {
      title: "Punto de venta",
      icon: <LocalGroceryStoreOutlinedIcon />,
      id: 4,
      url: "/pos/login/"
    },

  ]
  const item2 = [
    {
      title: "Analizar las tendencias de la industria",
      id: 1,
    },
    {
      title: "Análisis competitivo",
      id: 2,
    },
    {
      title: "Análisis de mercado",
      id: 3,
    },
    {
      title: "Análisis de la cadena de valor",
      id: 4,
    },

  ]
  const item3 = [
    {
      id: 1,
      title: "Analizar las tendencias de la industria",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat,"
    },
  ]




  return (
    <Container sx={{ paddingY: "36px", position: "relative", height: "100vh" }}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h5" gutterBottom color="#60269E">
            <strong>KesKi IA</strong>
          </Typography>
        </Grid>
        <Grid item xs={4} columnGap={10}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <BorderLinearProgress variant="determinate" value={50} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="p" gutterBottom color="#605D5D">
                1000 créditos usados (50%)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {send ? (
        <Grid
          container
          xs={12}
          mt={10}
          spacing={3}
          sx={{ overflow: "scroll", paddingBottom: "150px" }}
        >
          <Grid item container justifyContent="flex-end" xs={12}>
            <Box
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 0,
                padding: "5px",
              }}
              xs={4}
            >
              {qa}
            </Box>
          </Grid>
          {answer ? (
            <>
              <Grid item xs={7}>
                <Box
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 15,
                    padding: "5px",
                  }}
                  xs={4}
                >
                  {answer.interpretation}
                </Box>
              </Grid>
              <Grid item xs={7}>
                <div dangerouslySetInnerHTML={{ __html: answer.html[0] }} />
                <div dangerouslySetInnerHTML={{ __html: answer.html[1] }} />
              </Grid>
            </>
          ) : (
            <Grid item xs={7}>
              <Skeleton variant="rounded" width={"100%"} height={100} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container xs={12} mt={10} justifyContent={"center"} spacing={3}>
          <Grid item xs={8}>
            <Typography variant="h4" gutterBottom color="#605D5D">
              <strong>Bienvenido a Keski </strong>
              <img src={images.ia} alt="ia" />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="#605D5D" textAlign={"justify"}>
              Con inteligencia artificial, recolecta y analiza datos,
              garantizando monitoreo continuo y rápida reacción ante cambios en
              el mercado y tus operaciones, facilitando decisiones estratégicas
              y operativas.
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="#605D5D" textAlign={"justify"}>
              Empieza escribiendo una tarea y Chat se encargará del resto. ¿No
              sabes por dónde comenzar? Explora la biblioteca de sugerencias
              (Prompts) para inspirarte.
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BoxButton
                  img={images.file}
                  text={"Sales report by branch for the last semester"}
                  onClick={() => {
                    setQa("Sales report by branch for the last semester");
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <BoxButton
                  img={images.graph}
                  text={
                    "What are the 3 most expensive items in my catalog? Display it in a chart"
                  }
                  onClick={() => {
                    setQa(
                      "What are the 3 most expensive items in my catalog? Display it in a chart"
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <BoxButton
                  img={images.ticket}
                  text={"Calculate the average weekly ticket"}
                  onClick={() => {
                    setQa("Calculate the average weekly ticket");
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        mt={10}
        xs={8}
        sx={{
          position: "fixed",
          bottom: "30px",
          zIndex: "1",
          backgroundColor: "#fff",
        }}
      >
        <Grid item xs={12}>
          <Box
            width={"100%"}
            height={"67px"}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.02)",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              alignItems: "center",
              display: "flex",
              gap: "30px",
              paddingLeft: "80px",
            }}
          >
            <TextField
              label="Escribe tu consulta"
              value={qa}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  getIAResponse();
                }
              }}
              sx={{
                border: 0,
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            />
          </Box>
          <Box
            width={"100%"}
            height={"67px"}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              alignItems: "center",
              display: "flex",
              gap: "30px",
              paddingLeft: "80px",
            }}
          >
            <Grid container sx={12}>
              <Grid item xs={10}>
                <Button
                  onClick={() => {
                    setActiveTab("initial");
                    handleClickOpenModal();
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textTransform: "none", // Para evitar que el texto se ponga en mayúsculas
                    backgroundColor: "transparent", // Elimina el fondo del botón
                    padding: 0, // Quita el padding extra que pueda añadir el botón
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.05)", // Efecto hover ligero
                    },
                  }}
                >
                  <img src={images.prompts} alt="file" height={20} />
                  <Typography color="#605D5D" textAlign={"justify"}>
                    Prompts
                  </Typography>
                </Button>
                <ModalRight
                  open={openModal}
                  setOpen={setOpenModal}
                  title={<Typography variant="h6" sx={{ fontWeight: 'bold', }}>Prompts</Typography>}
                  size="sm"
                >
                  <Divider />
                  <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '16px' }}>
                    <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                      {activeTab === "initial" ?
                        //Initial tab
                        <>
                          <GenericList item={item} handleChangeTab={() => setActiveTab("list")} handleChangeId={setId} />
                        </>
                        : activeTab === "list" ?
                          //Propmt personalizados
                          <Box sx={{ width: '100%', }}>
                            <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                              <Typography variant="h6" component="div" sx={{ color: "text.primary" }}>
                                <IconButton onClick={() => setActiveTab("initial")} > <ArrowBackIosNewIcon /> </IconButton>
                                <strong>Prompts tienda en linea </strong>
                              </Typography>
                            </Box>
                            <GenericList item={item2} handleChangeTab={() => setActiveTab("show")} handleChangeId={setShowId} />

                          </Box>
                          :
                          //Show prompts
                          <Box sx={{ width: '100%', }}>
                            <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                              <Typography variant="h6" component="div" sx={{ color: "text.primary" }}>
                                <IconButton onClick={() => setActiveTab("list")} > <ArrowBackIosNewIcon /> </IconButton> <strong> Show props </strong>
                              </Typography>
                            </Box>
                            <DataPrompts data={item3} />
                          </Box>
                      }
                    </Grid>

                  </Grid>

                </ModalRight>
              </Grid>
              <Grid item xs={2}>
                <img
                  src={images.send}
                  alt="file"
                  height={26}
                  style={{ cursor: "pointer" }}
                  onClick={() => getIAResponse()}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export const BoxButton = ({ img, text, onClick }) => {
  return (
    <Box
      width={"100%"}
      height={"67px"}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.02)",
        borderRadius: 25,
        alignItems: "center",
        display: "flex",
        gap: "30px",
        paddingLeft: "80px",
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <img src={img} alt="file" height={20} />
      <Typography color="#605D5D" textAlign={"justify"}>
        {text}
      </Typography>
    </Box>
  );
};
